import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import sliderImage1 from "../../assets/img/home/slider-img-1.jpg";
import sliderImage2 from "../../assets/img/home/Blue-Coast-Cleaning-Service-banner.jpg";
import sliderImage3 from "../../assets/img/home/professional-cleaning-service-person-using-vacuum-cleaner-office-1-scaled.jpg";

import servicesCardImg2 from "../../assets/img/home/services-card-2.jpg";
import servicesCardImg4 from "../../assets/img/home/services-card-4.jpg";
import servicesCardImg5 from "../../assets/img/home/services-card-5.jpg";
import servicesCardImg6 from "../../assets/img/home/services-card-6.jpg";
import servicesCardImg7 from "../../assets/img/home/services-card-7.jpg";
import servicesCardImg8 from "../../assets/img/home/services-card-8.jpeg";

import cleaningImg4 from "../../assets/img/home/cleaning-img-4.png";
import cleaningImg5 from "../../assets/img/home/cleaning-img-5.png";
import cleaningImg6 from "../../assets/img/home/cleaning-img-6.jpg";
import cleaningImg7 from "../../assets/img/home/cleaning-img-7.png";
import cleaningImg8 from "../../assets/img/home/cleaning-img-8.png";
import cleaningImg9 from "../../assets/img/home/cleaning-img-9.jpeg";

import chooseServiceImg1 from "../../assets/img/home/choose-Service-Img-1.jpg";

import BgBanner1 from "../../assets/img/bg-banner-01.png";

import AppImage from "../../assets/img/home/app-img.png";

import googlePlayIcon from "../../assets/img/home/google-play.png";
import appStoreIcon from "../../assets/img/home/app-strore.png";
import loaderGif from '../../assets/img/home/loader.gif';

import { HomeRightBottom, MapUser } from "../imagepath";
import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import Header from "../header/index-two";
import { Footer } from "../footer";
import "./Home.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation, Autoplay, Pagination } from "swiper/modules";
export const Home2 = () => {
  useEffect(() => {
    document.body.className = "home-two";

    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log(userData, "userData");

  const navigate = useNavigate();

  const [serviceTypeDetails, setServiceTypeDetails] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  console.log(serviceTypeDetails, "serviceTypeDetails");

  const ServiceTypeFunction = () => {
    setIsLoader(true);
    axios
      .get(
        `${process.env.React_App_Url}/api/v1/service/type/listForb2cClient`,
        {
          headers: {
            authorization: `Bearer ${userData?.accessToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response, "asdasdasd");
        setServiceTypeDetails(response?.data);
        setIsLoader(false);
        toast.success(response.data, { position: "top-right" });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      });
  };

  useEffect(() => {
    ServiceTypeFunction();
  }, []);

  const RedirectToSubservice = (event) => {
    if (userData === null) {
      navigate("/login");
    } else {
      navigate("/HomeCleaning", { state: event });
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <Header />

        <div className="row m-0 p-0">
          <Swiper
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              className: "swiper-pagination-top-spacing",
            }}
            modules={[Pagination, Autoplay, Navigation]}
            navigation={true}
            className="mySwiper m-0"
          >
            <SwiperSlide>
              <div className="slider-content position-relative">
                <div className="slider-overlay d-flex align-items-center justify-content-center flex-column">
                  <h1
                    className="slider-title text-light text-center"
                    style={{ fontSize: "28px" }}
                  >
                    Where Cleanliness Shines Like a Star
                  </h1>
                  <p
                    className="text-light text-center col-lg-8"
                    style={{ fontSize: "20px" }}
                  >
                    We provide top-notch cleaning services tailored to your
                    needs. Our team ensures your home or office sparkles.
                  </p>
                  {/* Ovelay button */}

                  <button onClick={() => RedirectToSubservice(serviceTypeDetails.data[0])} className="joing-course-btn">
                    Book Now
                  </button>

                  {/* Ovelay button */}
                </div>
                <img src={sliderImage1} alt="" className="slider-img" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-content position-relative">
                <div className="slider-overlay d-flex align-items-center justify-content-center flex-column">
                  <h1
                    className="slider-title text-light text-center"
                    style={{ fontSize: "28px" }}
                  >
                    Excellence in Every Sweep
                  </h1>
                  <p
                    className="text-light text-center col-lg-8"
                    style={{ fontSize: "20px" }}
                  >
                    We use eco-friendly products for a safe
                    environment.Reliability and professionalism are our top
                    priorities.
                  </p>
                  {/* Ovelay button */}

                  <button onClick={() => RedirectToSubservice(serviceTypeDetails.data[0])} className="joing-course-btn">
                    Book Now
                  </button>

                  {/* Ovelay button */}
                </div>
                <img src={sliderImage2} alt="" className="slider-img" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-content position-relative">
                <div className="slider-overlay d-flex align-items-center justify-content-center flex-column">
                  <h1
                    className="slider-title text-light text-center"
                    style={{ fontSize: "28px" }}
                  >
                    Transforming Spaces, Elevating Lives
                  </h1>
                  <p
                    className="text-light text-center col-lg-8"
                    style={{ fontSize: "20px" }}
                  >
                    Experience a spotless space with our expert cleaners.
                    Affordable rates without compromising on quality.
                  </p>
                  {/* Ovelay button */}

                  <button onClick={() => RedirectToSubservice(serviceTypeDetails.data[0])} className="joing-course-btn">
                    Book Now
                  </button>

                  {/* Ovelay button */}
                </div>
                <img src={sliderImage3} alt="" className="slider-img" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div
          className="container d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: "60px" }}
        >
          <div className="row d-flex justify-content-center align-items-center mb-5">
            <div className="header-two-title w-100 text-center col-12">
              <h2 className="text-navy">Our Services</h2>
              <div
                className="header-two-text w-100 col-12"
                style={{ maxWidth: "100%" }}
              >
                <p className="mb-0 text-center">
                  At Elite Superstar, client happiness is our top priority. You
                  may be confident that your cleaning needs are being handled by
                  professionals since we value open communication,
                  accountability, and transparency. Our affordable pricing and
                  various scheduling options make it effortless for you to reap
                  the benefits of a neat and well-organized space without any
                  trouble.
                </p>
              </div>
            </div>
          </div>
          <div className="ux-design-five d-flex align-items-center justify-content-center">
            {isLoader ? (
              <div className="h-100 w-100 mb-5 mt-5 d-flex align-items-center justify-content-center">
                <img src={loaderGif} alt="" style={{ height: '100px', width: '100px' }} />
              </div>
            ) : (
              <div className="row col-lg-11 justify-content-center pb-4">
                {/* Col */}
                {serviceTypeDetails?.data?.length > 0 &&
                  serviceTypeDetails?.data?.map((items, index) => {
                    return (
                      <div
                        key={index}
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-3 mb-3"
                      >
                        <div
                          className="rounded-4 m-0 p-2 pb-4 border border-1"
                          style={{
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                        >
                          <div
                            className="course-box-five d-flex align-items-center justify-content-center"
                            style={{
                              backgroundImage:
                                items?.label === "Home Cleaning Services"
                                  ? `url(${servicesCardImg2})`
                                  : items?.label === "Office Cleaning Services"
                                    ? `url(${servicesCardImg8})`
                                    : items?.label === "Other Cleaning Services"
                                      ? `url(${servicesCardImg4})`
                                      : items?.label === "Massage Service"
                                        ? `url(${servicesCardImg7})`
                                        : items?.label === "Maintenance "
                                          ? `url(${servicesCardImg6})`
                                          : "",
                            }}
                          >
                            <div className="product-five h-100 w-100"></div>
                            {/* Ovelay button */}
                            <div className="joing-course-ovelay">
                              <button
                                onClick={() => RedirectToSubservice(items)}
                                className="joing-course-btn"
                              >
                                Book Now
                              </button>
                            </div>
                            {/* Ovelay button */}
                          </div>
                          <div className="w-100 mt-0 p-0 d-flex align-items-center justify-content-center">
                            <strong className="product-five-title mb-0 text-center text-dark text-center w-auto fs-1 text-lg">
                              {items?.label}
                            </strong>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* Repeat the same structure for other columns */}
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-3 mb-3">
                  <div
                    className="rounded-4 m-0 p-2 pb-4 border border-1"
                    style={{
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  >
                    <div
                      className="course-box-five d-flex align-items-center justify-content-center"
                      style={{ backgroundImage: `url(${servicesCardImg5})` }}
                    >
                      <div className="product-five h-100 w-100"></div>
                      <div className="joing-course-ovelay">
                        <button className="joing-course-btn">Book Now</button>
                      </div>
                    </div>
                    <div className="w-100 mt-0 p-0 d-flex align-items-center justify-content-center">
                      <strong className="product-five-title mb-0 text-center text-dark text-center w-auto fs-1 text-lg">
                        Booking Elite Apartment
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Grow up your skill section */}

        <section className="growup-section">
          <div className="home-two-shapes" style={{ right: "0", left: "2rem" }}>
            <img src={HomeRightBottom} alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className=" col-12 wow fadeInLeft" data-aos="fade-left">
                <div className="header-two-title">
                  <h2 className="text-navy text-center">
                    Best Cleaning Services at Elite Superstar in Dubai
                  </h2>
                  <div
                    className="header-two-text w-100"
                    style={{ maxWidth: "100%" }}
                  >
                    <p className="mb-0 text-center w-100">
                      Elite Superstar Cleaning Services is a premier cleaning
                      company based in Dubai, renowned for its top-notch
                      cleaning solutions tailored to both residential and
                      commercial clients. The company offers a wide range of
                      services including deep cleaning, regular maintenance,
                      carpet cleaning, and window washing. With a team of highly
                      trained professionals and a commitment to using
                      eco-friendly products, Elite Superstar Cleaning Services
                      ensures a spotless and hygienic environment for its
                      clients.
                    </p>
                    <p className="mt-3 text-center">
                      Their dedication to quality and customer satisfaction has
                      established them as a trusted name in the cleaning
                      industry in Dubai.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="growup-section">
          <div className="home-two-shapes">
            <img src={HomeRightBottom} alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
              <div
                className="col-xl-6 col-lg-6 col-md-5 col-12"
                data-aos="fade-right"
              >
                <div className="growup-images-two">
                  <div className="growup-skills-img">
                    <img
                      className="content-section-img rounded-circle"
                      src={cleaningImg4}
                      alt="image-banner"
                      title="image-banner"
                    />
                  </div>
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-7 col-12 wow fadeInLeft"
                data-aos="fade-left"
              >
                <div className="header-two-title">
                  <h2 className="text-navy">Home Cleaning Services</h2>
                  <div className="header-two-text">
                    <p className="mb-0" style={{ textAlign: "justify" }}>
                      Elite Superstar Cleaning Services in Dubai provides
                      exceptional home cleaning services designed to meet the
                      specific needs of residential clients. Their comprehensive
                      offerings include deep cleaning, regular housekeeping,
                      kitchen and bathroom sanitization, carpet and upholstery
                      cleaning, and more. The team of experienced and trained
                      cleaners uses eco-friendly and high-quality cleaning
                      products to ensure a safe and sparkling clean home.
                    </p>
                    <p className="mt-3" style={{ textAlign: "justify" }}>
                      With flexible scheduling and personalized cleaning plans,
                      Elite Superstar Cleaning Services guarantees a thorough
                      and professional cleaning experience, making homes in
                      Dubai shine.
                    </p>
                  </div>
                </div>
                <div className="about-button more-details">
                  <Link to="/register" className="discover-btn book-btn">
                    Booking Now <i className="fas fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="growup-section">
          <div className="home-two-shapes" style={{ right: "0", left: "2rem" }}>
            <img src={HomeRightBottom} alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center ">
              <div
                className="col-xl-6 col-lg-6 col-md-5 col-12"
                data-aos="fade-right"
              >
                <div className="growup-images-two">
                  <div className="growup-skills-img">
                    <img
                      className="rounded-circle content-section-img"
                      src={cleaningImg5}
                      alt="image-banner"
                      title="image-banner"
                      style={{ height: "500px", width: "500px" }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-7 col-12 wow fadeInLeft"
                data-aos="fade-left"
              >
                <div className="header-two-title">
                  <h2 className="text-navy">Office Cleaning Services</h2>
                  <div className="header-two-text">
                    <p className="mb-0" style={{ textAlign: "justify" }}>
                      Elite Superstar Cleaning Services in Dubai offers top-tier
                      office cleaning services, ensuring a pristine and
                      productive workplace environment. Their services include
                      daily or periodic cleaning, sanitization of high-touch
                      areas, carpet and upholstery cleaning, window cleaning,
                      and waste management. Utilizing eco-friendly products and
                      advanced cleaning techniques, their skilled team delivers
                      thorough and efficient cleaning tailored to the unique
                      needs of each office.
                    </p>
                    <p className="mt-3" style={{ textAlign: "justify" }}>
                      Committed to maintaining high standards of cleanliness and
                      hygiene, Elite Superstar Cleaning Services helps
                      businesses in Dubai create a professional and welcoming
                      atmosphere for employees and clients alike.
                    </p>
                  </div>
                </div>
                <div className="about-button more-details">
                  <Link to="/register" className="discover-btn book-btn">
                    Booking Now <i className="fas fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="growup-section">
          <div className="home-two-shapes">
            <img src={HomeRightBottom} alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
              <div
                className="col-xl-6 col-lg-6 col-md-5 col-12"
                data-aos="fade-right"
              >
                <div className="growup-images-two">
                  <div className="growup-skills-img">
                    <img
                      className=" content-section-img rounded-circle"
                      src={cleaningImg6}
                      alt="image-banner"
                      title="image-banner"
                      style={{ height: "500px" }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-7 col-12 wow fadeInLeft"
                data-aos="fade-left"
              >
                <div className="header-two-title">
                  <h2 className="text-navy">Book Elite Apartment</h2>
                  <div className="header-two-text">
                    <p className="mb-0" style={{ textAlign: "justify" }}>
                      Booking an apartment with Elite Superstar in Dubai offers
                      a luxurious and comfortable living experience. These elite
                      apartments are designed with modern amenities and stylish
                      interiors to cater to the needs of discerning residents.
                      Featuring spacious layouts, state-of-the-art facilities,
                      and stunning views, each apartment promises a high
                      standard of living. Residents can enjoy a range of
                      services, including 24/7 security, concierge services, and
                      access to exclusive community amenities such as swimming
                      pools, fitness centers, and recreational areas.
                    </p>
                    <p className="mt-3" style={{ textAlign: "justify" }}>
                      With its prime locations and exceptional service, Elite
                      Superstar ensures a seamless and enjoyable living
                      experience in Dubai.
                    </p>
                  </div>
                </div>
                <div className="about-button more-details">
                  <Link to="/register" className="discover-btn book-btn">
                    Booking Now <i className="fas fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="growup-section">
          <div className="home-two-shapes" style={{ right: "0", left: "2rem" }}>
            <img src={HomeRightBottom} alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center ">
              <div
                className="col-xl-6 col-lg-6 col-md-5 col-12"
                data-aos="fade-right"
              >
                <div className="growup-images-two">
                  <div className="growup-skills-img">
                    <img
                      className=" content-section-img"
                      src={cleaningImg7}
                      alt="image-banner"
                      title="image-banner"
                      style={{ height: "500px" }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-7 col-12 wow fadeInLeft"
                data-aos="fade-left"
              >
                <div className="header-two-title">
                  <h2 className="text-navy">Sofa, Carpet, Mattress Cleaning</h2>
                  <div className="header-two-text">
                    <p className="mb-0" style={{ textAlign: "justify" }}>
                      Elite Superstar Cleaning Services in Dubai specializes in
                      sofa, carpet, and mattress cleaning, ensuring a fresh and
                      hygienic home environment. Their expert team uses advanced
                      cleaning techniques and eco-friendly products to
                      effectively remove dirt, stains, and allergens from
                      upholstery and carpets. The thorough cleaning process
                      revitalizes the appearance and extends the lifespan of
                      sofas, carpets, and mattresses.
                    </p>
                    <p className="mt-3" style={{ textAlign: "justify" }}>
                      Whether dealing with stubborn stains or routine
                      maintenance, Elite Superstar provides professional and
                      reliable cleaning solutions, guaranteeing the highest
                      standards of cleanliness and comfort for their clients.
                    </p>
                  </div>
                </div>
                <div className="about-button more-details">
                  <Link to="/register" className="discover-btn book-btn">
                    Booking Now <i className="fas fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="growup-section">
          <div className="home-two-shapes">
            <img src={HomeRightBottom} alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
              <div
                className="col-xl-6 col-lg-6 col-md-5 col-12"
                data-aos="fade-right"
              >
                <div className="growup-images-two">
                  <div className="growup-skills-img">
                    <img
                      className=" content-section-img"
                      src={cleaningImg9}
                      alt="image-banner"
                      title="image-banner"
                      style={{ height: "500px" }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-7 col-12 wow fadeInLeft"
                data-aos="fade-left"
              >
                <div className="header-two-title">
                  <h2 className="text-navy">Maintenance</h2>
                  <div className="header-two-text">
                    <p className="mb-0" style={{ textAlign: "justify" }}>
                      Elite Superstar in Dubai offers comprehensive maintenance
                      services designed to keep properties in excellent
                      condition. Their skilled team handles a wide range of
                      tasks, including plumbing, electrical work, HVAC
                      maintenance, painting, and general repairs. Utilizing
                      high-quality materials and the latest techniques, they
                      ensure that all maintenance work is performed efficiently
                      and to the highest standards.
                    </p>
                    <p className="mt-3" style={{ textAlign: "justify" }}>
                      Whether it&apos;s routine upkeep or emergency repairs,
                      Elite Superstar provides prompt and reliable service,
                      helping clients maintain the safety, functionality, and
                      aesthetic appeal of their homes or offices in Dubai.
                    </p>
                  </div>
                </div>
                <div className="about-button more-details">
                  <Link to="/register" className="discover-btn book-btn">
                    Booking Now <i className="fas fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="growup-section">
          <div className="home-two-shapes" style={{ right: "0", left: "2rem" }}>
            <img src={HomeRightBottom} alt="" />
          </div>
          <div className="container">
            <div className="row align-items-center ">
              <div
                className="col-xl-6 col-lg-6 col-md-5 col-12"
                data-aos="fade-right"
              >
                <div className="growup-images-two">
                  <div className="growup-skills-img">
                    <img
                      className=" content-section-img"
                      src={cleaningImg8}
                      alt="image-banner"
                      title="image-banner"
                      style={{ height: "500px" }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-7 col-12 wow fadeInLeft"
                data-aos="fade-left"
              >
                <div className="header-two-title">
                  <h2 className="text-navy">Massage Service</h2>
                  <div className="header-two-text">
                    <p className="mb-0" style={{ textAlign: "justify" }}>
                      Elite Superstar in Dubai offers luxurious massage services
                      designed to provide relaxation and rejuvenation. Their
                      professional therapists are skilled in various massage
                      techniques, including Swedish, deep tissue, aromatherapy,
                      and hot stone massages. Using high-quality oils and
                      products, they create a serene and soothing environment
                      for a truly indulgent experience.
                    </p>
                    <p className="mt-3" style={{ textAlign: "justify" }}>
                      Whether seeking relief from stress, muscle tension, or
                      simply wanting to unwind, clients can enjoy personalized
                      massage sessions tailored to their needs. With a
                      commitment to exceptional service and well-being, Elite
                      Superstar ensures a relaxing and revitalizing experience
                      for every client.
                    </p>
                  </div>
                </div>
                <div className="about-button more-details">
                  <Link to="/register" className="discover-btn book-btn">
                    Booking Now <i className="fas fa-arrow-right ms-2"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Grow up your skill section */}

        {/* How it works section */}
        <section
          className="section share-knowledge mt-5"
          style={{ backgroundImage: "url(" + BgBanner1 + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="header-two-title">
                <h2 className="text-navy text-center">How it Works</h2>
                <div className="header-two-text">
                  <p className="text-secondary text-center">
                    At Elite superstar, we make booking a home cleaning service
                    as easy and convenient as possible. Our streamlined process
                    ensures you get a sparkling clean home without any hassle.
                  </p>
                </div>
              </div>
              <div className="row m-0 p-0 flex-wrap">
                <div className="col-lg-3 col-sm-6 col-12 p-2">
                  <div className="card process-card bg-white p-4 py-5 h-100 d-flex flex-column align-items-center">
                    <div className="count d-flex align-items-center justify-content-center rounded-circle bg-dark ">
                      <h4 className="text-center m-0 p-0 text-white">1</h4>
                    </div>
                    <div className="content col-12 mt-3 flex-grow-1">
                      <h5 className="m-0 p-0 text-center">
                        Explore Our Services
                      </h5>
                      <p
                        className="m-0 p-0 text-secondary mt-3"
                        style={{ fontSize: "0.9rem", textAlign: "justify" }}
                      >
                        Visit our website, Install our Mobile App or contact our
                        customer service to learn about the wide range of
                        services we offer, including cleaning, maintenance,
                        massage, and shopping.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12 p-2">
                  <div className="card process-card bg-white p-4 py-5 h-100 d-flex flex-column align-items-center">
                    <div className="count d-flex align-items-center justify-content-center rounded-circle bg-dark ">
                      <h4 className="text-center m-0 p-0 text-white">2</h4>
                    </div>
                    <div className="content col-12 mt-3 flex-grow-1">
                      <h5 className="m-0 p-0 text-center">Book a Service</h5>
                      <p
                        className="m-0 p-0 text-secondary mt-3"
                        style={{ fontSize: "0.9rem", textAlign: "justify" }}
                      >
                        Select the service you need and book an appointment at
                        your convenience. You can do this online, through our
                        website, or through our mobile app. Our flexible
                        scheduling options ensure that you find a time that
                        suits you best.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12 p-2">
                  <div className="card process-card bg-white p-4 py-5 h-100 d-flex flex-column align-items-center">
                    <div className="count d-flex align-items-center justify-content-center rounded-circle bg-dark ">
                      <h4 className="text-center m-0 p-0 text-white">3</h4>
                    </div>
                    <div className="content col-12 mt-3 flex-grow-1">
                      <h5 className="m-0 p-0 text-center">
                        Personalized Consultation
                      </h5>
                      <p
                        className="m-0 p-0 text-secondary mt-3"
                        style={{ fontSize: "0.9rem", textAlign: "justify" }}
                      >
                        For certain services likeB2B Services, our team will
                        conduct a brief consultation to understand your specific
                        needs and preferences, ensuring tailored service
                        delivery.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 col-12 p-2">
                  <div className="card process-card bg-white p-4 py-5 h-100 d-flex flex-column align-items-center">
                    <div className="count d-flex align-items-center justify-content-center rounded-circle bg-dark ">
                      <h4 className="text-center m-0 p-0 text-white">4</h4>
                    </div>
                    <div className="content col-12 mt-3 flex-grow-1">
                      <h5 className="m-0 p-0 text-center">Service Execution</h5>
                      <p
                        className="m-0 p-0 text-secondary mt-3"
                        style={{ fontSize: "0.9rem", textAlign: "justify" }}
                      >
                        Our professional and skilled staff will arrive at your
                        location on time, equipped with high-quality tools and
                        products. They will perform the service efficiently,
                        adhering to the highest standards of quality and
                        professionalism.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /How it works section */}

        {/* reasons to choose us */}
        <section className="section  mt-5">
          <div className="container">
            <div className="row m-0 p-0">
              <div
                className="col-md-6 d-flex align-items-center justify-content-center"
                data-aos="fade-left"
              >
                <img
                  src={chooseServiceImg1}
                  alt=""
                  className="choose-service-img"
                />
              </div>
              <div className="col-md-6 d-flex align-items-center mt-4 mt-md-0">
                <div className="join-mentor aos" data-aos="fade-up">
                  <h2 className="m-0 p-0 mb-3">Reasons to Choose Us</h2>
                  <p className="m-0 p-0 mb-5">
                    Behind our commitment to excellence are few key attributes
                    that define who we are and what makes us different from any
                    other.
                  </p>

                  <ul className="course-list">
                    <li className="" style={{ textAlign: "justify" }}>
                      <i className="fa-solid fa-circle-check" />
                      Top-Rated Company: We hold a successful track record of
                      satisfying our customers and getting back their bond
                      money.
                    </li>
                    <li style={{ textAlign: "justify" }}>
                      <i className="fa-solid fa-circle-check" />
                      Superior Quality: We use the most excellent quality tools
                      and equipment to get all the dust and dirt out of your
                      premises.
                    </li>
                    <li style={{ textAlign: "justify" }}>
                      <i className="fa-solid fa-circle-check" />
                      Eco-Friendly Products: We use biodegradable products which
                      do not harm the environment, pets or humans in any way.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* reasons to choose us */}

        {/* *Our clients */}
        {/* <section className="container mt-5">
          <div className="row m-0 p-0 mt-5">
            <div className="header-two-title w-100 text-center col-12">
              <h2 className="text-navy">Our Clients</h2>
            </div>
            <div className="row m-0 p-0 flex-wrap">
              <div className="col-md-4 client-item  p-lg-5 p-1 d-flex align-items-center justify-content-center border-start-0 border-top-0 ">
                <img src={clientImg1} alt="" className="client-img w-100" />
              </div>
              <div className="col-md-4 client-item  p-lg-5 p-1 d-flex align-items-center justify-content-center border-start-0 border-top-0">
                <img src={clientImg2} alt="" className="client-img w-100" />
              </div>
              <div className="col-md-4 client-item  p-lg-5 p-1 d-flex align-items-center justify-content-center border-start-0 border-top-0 border-end-0">
                <img src={clientImg3} alt="" className="client-img w-100" />
              </div>
              <div className="col-md-4 client-item  p-lg-5 p-1 d-flex align-items-center justify-content-center border-start-0 border-top-0 ">
                <img src={clientImg4} alt="" className="client-img w-100" />
              </div>
              <div className="col-md-4 client-item  p-lg-5 p-1 d-flex align-items-center justify-content-center border-start-0 border-top-0">
                <img src={clientImg3} alt="" className="client-img w-100" />
              </div>
              <div className="col-md-4 client-item  p-lg-5 p-1 d-flex align-items-center justify-content-center border-start-0 border-top-0  border-end-0">
                <img src={clientImg4} alt="" className="client-img w-100" />
              </div>
              <div className="col-md-4 client-item  p-lg-5 p-1 d-flex align-items-center justify-content-center border-start-0 border-top-0 border-bottom-0">
                <img src={clientImg4} alt="" className="client-img w-100" />
              </div>
              <div className="col-md-4 client-item  p-lg-5 p-1 d-flex align-items-center justify-content-center border-start-0 border-top-0 border-bottom-0">
                <img src={clientImg3} alt="" className="client-img w-100" />
              </div>
              <div className="col-md-4 client-item  p-lg-5 p-1 d-flex align-items-center justify-content-center border-start-0 border-top-0  border-end-0 border-bottom-0">
                <img src={clientImg4} alt="" className="client-img w-100" />
              </div>
            </div>
          </div>
        </section> */}
        {/**Our clients */}

        {/** App banner */}
        <section className="section new-course mt-5 pb-0">
          <div className="container">
            <div className="row m-0 p-0">
              <div className="col-md-6 col-12">
                <div className="section-header aos" data-aos="fade-up">
                  <div className="section-sub-head">
                    <span>Take Us Everyone With You</span>
                    <h2>Get the Elite SuperStar App now!</h2>
                  </div>
                </div>
                <div className="row m-0 p-0 d-flex flex-wrap mt-5 position-relative z-5">
                  <Link
                    className="w-auto m-0 p-0"
                    to="https://play.google.com/store/apps/details?id=com.chirayusoft.elitestarjewellery&pcampaignid=web_share"
                    target="blank"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={googlePlayIcon}
                      alt=""
                      className="mt-2"
                      style={{ height: "80px", width: "300px" }}
                    />
                  </Link>
                  <Link
                    className="w-auto m-0 p-0 ms-md-2 ms-0"
                    to="https://play.google.com/store/apps/details?id=com.chirayusoft.elitestarjewellery&pcampaignid=web_share"
                    target="blank"
                  >
                    <img
                      src={appStoreIcon}
                      alt=""
                      className="mt-2"
                      style={{ height: "80px", width: "300px" }}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-6 col-12 d-flex align-items-center justify-content-end">
                <img
                  src={AppImage}
                  alt=""
                  style={{ height: "400px", width: "400px" }}
                />
              </div>
            </div>
          </div>
        </section>
        {/** App banner */}

        {/* Testimonials */}
        <section className="testimonial-sec">
          {/* <section className="container ">
            <div className="header-two-title w-100 text-center col-12">
              <h2 className="text-navy">What they&apos;re saying</h2>
            </div>
            <div className="row m-0 p-0 mt-4 align-items-center justify-content-center">
              <Swiper
                slidesPerView={1}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                  className: "swiper-pagination-top-spacing",
                }}
                modules={[Pagination, Autoplay, Navigation]}
                navigation={true}
                className="testimonial-slider m-0 col-lg-7"
              >
                <SwiperSlide>
                  <div className="slider-testimonial-content border border-1 rounded-5 p-3 pb-5 pt-5 d-flex flex-column align-items-center justify-content-center position-relative ">
                    <p className="text-secondary text-center">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      In est sem, ultrices ornare molestie sit amet, placerat
                      vel arcu. Phasellus quis massa id sem pretium dictum.
                      Donec sed sollicitudin est, sit amet eleifend ipsum.
                      Vivamus nec pretium turpis.
                    </p>
                    <div className="testimonial-image">
                      <img
                        src={testimonialProfileImg1}
                        alt="image"
                        className="testimonial-img rounded-circle"
                        style={{ height: "70px", width: "70px" }}
                      />
                    </div>
                    <p className="fs-5 fw-semibold m-0 p-0 mt-3">Mike LLOYD</p>
                    <p className="fs-6 fw-regular text-secondary">
                      UI Designer, Yahoo
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slider-testimonial-content border border-1 rounded-5 p-3 pb-5 pt-5 d-flex flex-column align-items-center justify-content-center position-relative ">
                    <p className="text-secondary text-center">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      In est sem, ultrices ornare molestie sit amet, placerat
                      vel arcu. Phasellus quis massa id sem pretium dictum.
                      Donec sed sollicitudin est, sit amet eleifend ipsum.
                      Vivamus nec pretium turpis.
                    </p>
                    <div className="testimonial-image">
                      <img
                        src={testimonialProfileImg1}
                        alt="image"
                        className="testimonial-img rounded-circle"
                        style={{ height: "70px", width: "70px" }}
                      />
                    </div>
                    <p className="fs-5 fw-semibold m-0 p-0 mt-3">Mike LLOYD</p>
                    <p className="fs-6 fw-regular text-secondary">
                      UI Designer, Yahoo
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slider-testimonial-content border border-1 rounded-5 p-3 pb-5 pt-5 d-flex flex-column align-items-center justify-content-center position-relative ">
                    <p className="text-secondary text-center">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      In est sem, ultrices ornare molestie sit amet, placerat
                      vel arcu. Phasellus quis massa id sem pretium dictum.
                      Donec sed sollicitudin est, sit amet eleifend ipsum.
                      Vivamus nec pretium turpis.
                    </p>
                    <div className="testimonial-image">
                      <img
                        src={testimonialProfileImg1}
                        alt="image"
                        className="testimonial-img rounded-circle"
                        style={{ height: "70px", width: "70px" }}
                      />
                    </div>
                    <p className="fs-5 fw-semibold m-0 p-0 mt-3">Mike LLOYD</p>
                    <p className="fs-6 fw-regular text-secondary">
                      UI Designer, Yahoo
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </section> */}

          <div className="container">
            <div className="testimonial-two-img">
              <img src={MapUser} alt="" className="img-fluid" />
            </div>
            <div className="testimonial-subhead-two">
              <div
                className="col-xl-8 col-lg-10 col-md-10 mx-auto"
                data-aos="fade-down"
              >
                <div className="testimonial-inner">
                  <div className="header-two-title testimonial-head-two text-center">
                    <h2 data-aos="fade-down">
                      Join over <span>50K</span> Customers
                    </h2>
                    <div className="header-two-text text-center">
                      <p>
                        Get certified, master modern tech skills, and level up
                        your career — whether you’re starting out or a seasoned
                        pro. 95% of eLearning learners report our hands-on
                        content directly helped their careers.
                      </p>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col-md-4 col-sm-12" data-aos="fade-up">
                      <div className="course-count-two course-count">
                        <h4>
                          <span className="counterUp text-orange">
                            <CountUp start={0} end={50000} delay={0} />
                          </span>
                        </h4>
                        <h5>Customers</h5>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12" data-aos="fade-up">
                      <div className="course-count-two  course-count">
                        <h4>
                          <span className="counterUp text-green">
                            {" "}
                            <CountUp
                              start={0}
                              end={10500}
                              delay={0.2}
                              duration={2}
                            />
                          </span>
                        </h4>
                        <h5>Total Customers</h5>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12" data-aos="fade-up">
                      <div className="course-count-two  course-count">
                        <h4>
                          <span className="counterUp text-blue">
                            <CountUp start={0} end={253085} delay={0} />
                          </span>
                        </h4>
                        <h5>Customers Worldwide</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Testimonials */}

        {/* Footer */}
        <Footer />
        {/* Footer */}
      </div>
    </>
  );
};
