import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Home2 } from "./components/home";
import Header from "./components/header/index-two";
import { Footer } from "./components/footer";
import ContactUs from "./components/ContactUs/ContactUs";
import AboutUs from "./components/AboutUs/AboutUs";
import Booking from "./components/Booking/Booking";
import NewBookingForm from "./components/NewBookingForm/NewBookingform";
import HomeCleaningService from "./components/HomeCleaningService/HomeCleaningServices";
import SubServices from "./components/SubServices/SubServices";
import Login from "./components/login";
import Register from "./components/register";
import ForgotPassword from "./components/forgotPassword";
import Invoice from "./components/Invoice/Invoice";
import SalarySlip from "./components/SalarySlip/SalarySlip";
import InstructorCourse from "./components/Instructor/course";
import BookingDashboard from "./components/Instructor/AllpagesIndashboard/BookingDashboard";
import B2BProfile from "./components/Instructor/AllpagesIndashboard/B2BProfile";
import B2BRewards from './components/Instructor/AllpagesIndashboard/Rewards';
import InstructorNotification from "./components/Instructor/notification";
import InvoiceDashboard from "./components/Instructor/AllpagesIndashboard/InvoiceDashboard";
import FoundAndMissingItem from "./components/Instructor/AllpagesIndashboard/FoundandMissing"
import AddUnit from "./components/Instructor/AllpagesIndashboard/AddUnit";
import Units from "./components/Instructor/AllpagesIndashboard/Units";
import BookngList from "./components/Instructor/AllpagesIndashboard/BookngList";

import Dashboard from "./components/Instructor/dashboard/Dashboard";
import B2CDashboard from "./components/Instructor/B2CDashboard/B2CDashboard";
import B2CBookngList from "./components/Instructor/B2CDashboard/B2CBookingList";
import B2CRewards from "./components/Instructor/B2CDashboard/B2CRewards";
import ProtectedRoute from "./components/ProtectedRoute";
import Team from "./components/Team/team";
import { Toaster } from "react-hot-toast";

const Approuter = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Home2 />} />
          <Route path="Header" element={<Header />} />
          <Route path="Footer" element={<Footer />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="AboutUs" element={<AboutUs />} />
          <Route path="Booking" element={<Booking />} />
          <Route path="BookingForm" element={<NewBookingForm />} />
          <Route path="HomeCleaning" element={<HomeCleaningService />} />
          <Route path="SubServices" element={<SubServices />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="ForgotPassword" element={<ForgotPassword />} />
          <Route
            path="Invoice"
            element={<ProtectedRoute element={Invoice} />}
          />
          <Route
            path="SalarySlip"
            element={<ProtectedRoute element={SalarySlip} />}
          />
          <Route
            path="Dashboard"
            element={<ProtectedRoute element={Dashboard} />}
          />
          <Route
            path="B2CDashboard"
            element={<ProtectedRoute element={B2CDashboard} />}
          />
          <Route
            path="InstructorCourse"
            element={<ProtectedRoute element={InstructorCourse} />}
          />
          <Route
            path="BookingDashboard"
            element={<ProtectedRoute element={BookingDashboard} />}
          />
          <Route
            path="B2Bprofile"
            element={<ProtectedRoute element={B2BProfile} />}
          />
          <Route
            path='B2BRewards'
            element={<ProtectedRoute element={B2BRewards} />}
          />
          <Route
            path="InstructorNotification"
            element={<ProtectedRoute element={InstructorNotification} />}
          />
          <Route
            path="InvoiceDashboard"
            element={<ProtectedRoute element={InvoiceDashboard} />}
          />
          <Route
            path="FoundAndMissingItem"
            element={<ProtectedRoute element={FoundAndMissingItem} />}
          />
          <Route path="Units" element={<ProtectedRoute element={AddUnit} />} />
          <Route path="AddUnit" element={<ProtectedRoute element={Units} />} />
          <Route
            path="BookngList"
            element={<ProtectedRoute element={BookngList} />}
          />
          <Route
            path="B2CBookngList"
            element={<ProtectedRoute element={B2CBookngList} />}
          />
          <Route
            path="B2CRewards"
            element={<ProtectedRoute element={B2CRewards} />}
          />
          <Route
            path="Dashboard"
            element={<ProtectedRoute element={Dashboard} />}
          />
          <Route path="team" element={<Team />} />
        </Routes>
      </BrowserRouter>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default Approuter;
