import React, { useEffect, useState } from "react";
import {
  // Bell,
  // Book,
  // DollarSign,
  Home,
  // Lock,
  // PieChart,
  Power,
  // RefreshCw,
  // Server,
  // Settings,
  // ShoppingBag,
  // Star,
  LogOut
  // Trash2,
  // User,
  // Users,
} from "react-feather";
import { InstructorProfileBg } from "../../imagepath";
import StickyBox from "react-sticky-box";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import profile from '../../../assets/img/logo/profile.png'

// eslint-disable-next-line react/prop-types
export default function B2CInstructorSidebar({ activeMenu }) {

  const navigate = useNavigate();

  const [id, setId] = useState("")

  const userData = JSON.parse(localStorage.getItem('userData'));
  console.log(id, userData, "1231231212")

  useEffect(() => {
    // Check if user is already logged in using localStorage
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      // Assuming you have a token or user check to determine authentication
      if (parsedUserData.user) {
        console.log(parsedUserData.user._id, "ssdDs");
        setId(parsedUserData.user._id)
        // Redirect to dashboard or authenticated page
        // navigate("/DashboardInstructor");
      }
    }
  }, []);



  // const userData = localStorage.getItem.parse("userData")
  // console.log(userData.user,"asdasdasdasdasdasdasd")

  const Logout = (event) => {
    event.preventDefault();
    const payload = {
      "user_id": userData?.user?.uuid
    }
    const apiUrl = `${process.env.React_App_Url}/api/v1/frontend/logout`;
    axios.post(apiUrl, payload)
      .then((response) => {
        localStorage.removeItem('userData');
        toast.success(response.data.message, { position: 'top-right' });
        navigate("/login");
      })
      .catch((error) => {
        console.error('Error logging out:', error);
        toast.error("Failed to logout. Please try again.");
      });
  };



  return (
    <div className="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar">
      <StickyBox offsetTop={20} offsetBottom={20}>
        <div className="sideStickyBar">
          <div className="settings-widget dash-profile">
            <div className="settings-menu p-0">
              <div className="profile-bg">
                {/* <h5>Beginner</h5> */}
                <img src={InstructorProfileBg} alt="" />
                <div className="profile-img">
                  <Link>
                    <img src={profile} alt="" />
                  </Link>
                </div>
              </div>
              <div className="profile-group">
                {/* <div className="profile-name text-center">
                  <h4>
                    <Link>Md Seraj </Link>
                  </h4>
                  <p>Super Admin</p>
                </div> */}
                {/* <div className="go-dashboard text-center">
                <Link className="btn btn-primary">
                  Create New Course
                </Link>
              </div> */}
                <center><strong>{userData?.user?.name}</strong></center>
              </div>
            </div>
          </div>
          <div className="settings-widget account-settings feather-icon">
            <div className="settings-menu">
              <h3>DASHBOARD</h3>
              <ul>


                <li className="nav-item">
                  <Link
                    to="/B2CDashboard"
                    className={
                      activeMenu === "DashboardInstructor" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Home size={20} /> My Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/B2CBookngList"
                    className={
                      activeMenu === "BookingList" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Power size={20} /> Booking List
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/B2CRewards"
                    className={
                      activeMenu === "B2CRewards" ? "nav-item active" : "nav-item"
                    }
                  >
                    <Power size={20} /> Rewards
                  </Link>
                </li>
                <li className="nav-item" onClick={Logout}>
                  <Link
                    to=" "
                    className={
                      activeMenu === "Logout" ? "nav-item active" : "nav-item"
                    }
                  >
                    <LogOut size={20} /> Logout
                  </Link>
                </li>
                {/* <li className="nav-item">
                <Link
                  to="/instructor-student-grid"
                  className={
                    activeMenu === ("StudentGrid" || "StudentList")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Users size={20} /> Students
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-payouts"
                  className={
                    activeMenu === "Payouts" ? "nav-item active" : "nav-item"
                  }
                >
                  <DollarSign size={20} /> Payouts
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-tickets"
                  className={
                    activeMenu === "Tickets" ? "nav-item active" : "nav-item"
                  }
                >
                  <Server size={20} /> Support Tickets
                </Link>
              </li> */}
              </ul>
              {/* <div className="instructor-title">
              <h3>ACCOUNT SETTINGS</h3>
            </div> */}
              {/* <ul>
              <li className="nav-item">
                <Link
                  to="/instructor-edit-profile"
                  className={
                    activeMenu === "EditProfile"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Settings size={20} /> Edit Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-security"
                  className={
                    activeMenu === "Security" ? "nav-item active" : "nav-item"
                  }
                >
                  <User size={20} /> Security
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-social-profile"
                  className={
                    activeMenu === "SocialProfile"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <RefreshCw size={20} /> Social Profiles
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-notifications"
                  className={
                    activeMenu === "Notification"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Bell size={20} /> Notifications
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-profile-privacy"
                  className={
                    activeMenu === "ProfilePrivacy"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Lock size={20} /> Profile Privacy
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-delete-profile"
                  className={
                    activeMenu === "DeleteProfile"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Trash2 size={20} /> Delete Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/instructor-linked-account"
                  className={
                    activeMenu === "LinkedAccount"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <User size={20} /> Linked Accounts
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  <Power size={20} /> Sign Out
                </Link>
              </li>
            </ul> */}
            </div>
          </div>
        </div>
      </StickyBox>
    </div>
  );
}
