import React, { useState, useEffect } from "react";
import InstructorSidebar from "../sidebar";
import Header from "../../header/index-two";
import { Footer } from "../../footer";
// import { Search } from "react-feather";
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2'
import axios from "axios";


export default function BookingDashboard() {

  const userData = JSON.parse(localStorage.getItem('userData'));

  const style = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: "white",
      width: "100%",
      height: "40px",
      color: "black",
      minHeight: "40px",
      paddingLeft: "5px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderRadius: state.isSelected ? "0" : "10px",
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      outline: "none",
    }),
    menu: (base) => ({ ...base, marginTop: "0px" }),
    menuList: (base) => ({ ...base, padding: "0" }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FFDEDA" : "white",
      color: "black",
      fontSize: "14px",
      "&:hover": {
        backgroundColor: "#FFDEDA",
        // #dddddd
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "black",
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "250ms",
    }),
  };

  const [propertyNameDetails, setPropertyNameDetails] = useState('');
  const [propertyNameoptionvalue, setPropertyNameOptionValue] = useState('');
  const [AdditionalpropertyDetails, setAdditionalPropertyDetails] = useState('');

  const [UnitNoData, setUnitNoData] = useState('');

  const [propertyTypeDetails, setPropertyTypeDetails] = useState('');
  console.log(AdditionalpropertyDetails, "AdditionalpropertyDetails");

  const [serviceTypeDetails, setServiceTypeDetails] = useState('');
  const [serviceoptionvalue, setServiceoptionValue] = useState('');

  const [datevalue, setDateValue] = useState('');
  const [timevalue, setTimeValue] = useState('');
  const [notevalue, setNoteValue] = useState('');

  // const [unitNumber, setUnitNumber] = useState('');
  // const [propertyType, setPropertyType] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [AreaName, setAreaName] = useState('');

  const [showHide, setShowHide] = useState(false);

  const propertyTypeMapping = {
    "STUDIO": "Studio",
    "ONE BHK": "1 BHK",
    "TWO BHK": "2 BHK",
    "THREE BHK": "3 BHK",
    "FOUR BHK": "4 BHK",
    "FIVE BHK": "5 BHK",
    "TWO BHK VILLA": "2 BHK VILLA",
    "THREE BHK VILLA": "3 BHK VILLA",
    "FOUR BHK VILLA": "4 BHK VILLA",
    "FIVE BHK VILLA": "5 BHK VILLA",
    "SIX BHK VILLA": "6 BHK VILLA",
  };

  useEffect(() => {
    if (propertyTypeDetails?.data && propertyTypeDetails.data.length > 0) {
      // setPropertyType(propertyTypeDetails.data[0].property_type);
      setAddressValue(propertyTypeDetails.data[0].address)
      // setUnitNumber(propertyTypeDetails.data[0].unit_number)
      setAreaName(propertyTypeDetails.data[0].area_name)
    }
  }, [propertyTypeDetails]);


  const PropertyNameDropdownFun = () => {
    axios.get(`${process.env.React_App_Url}/api/v1/frontend/unitdropdownList/${userData?.user?.uuid}`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    },
    )
      .then((response) => {
        console.log(response, "asdasdasd");
        setPropertyNameDetails(response?.data)
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      })
  }

  const [unitDropDown, setUnitDropDown] = useState([]);
  const [typeDropDown, setTypeDropDown] = useState([]);

  console.log(unitDropDown, "ghdgfchdsgjdsk");


  const PropertyTypeDropdownFun = (uuid) => {
    axios.get(`${process.env.React_App_Url}/api/v1/frontend/unitDetails/${uuid}`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    },
    )
      .then((response) => {
        console.log(response, "asdasdasd");
        const emptyArray = [];
        response?.data?.data[0]?.unit_details?.map((item) => {
          emptyArray.push({ label: item?.unit_number, value: item?.unit_number })
        })
        const emptyArray1 = [];
        response?.data?.data[0]?.unit_details?.map((item) => {
          const formattedLabel = propertyTypeMapping[item?.property_type?.replaceAll('_', ' ').toUpperCase()] || item?.property_type?.replaceAll('_', ' ').toUpperCase();
          emptyArray1.push({ label: formattedLabel, value: item?.property_type })
        })
        setUnitDropDown(emptyArray);
        setTypeDropDown(emptyArray1);

        setPropertyTypeDetails(response?.data)
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      })
  }

  const ServiceTypeFun = () => {
    axios.get(`${process.env.React_App_Url}/api/v1/service/type/listForb2bClient`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    },
    )
      .then((response) => {
        console.log(response, "asdasdasd");
        setServiceTypeDetails(response?.data)
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      })
  }

  const AdditionalPropertyFun = () => {
    axios.get(`${process.env.React_App_Url}/api/v1/frontend/additional/serviceList`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    },
    )
      .then((response) => {
        console.log(response, "asdasdasd");
        setAdditionalPropertyDetails(response?.data)
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      })
  }

  const navigate = useNavigate();

  useEffect(() => {
    PropertyNameDropdownFun()
    ServiceTypeFun()
    AdditionalPropertyFun()
  }, [])

  const UnitNoOptions = [{ labe: "Select Property Name", value: "" }]

  if (UnitNoData?.data?.length > 0) {
    UnitNoData?.data?.map((items) => {
      return UnitNoOptions?.push({ label: items?.property_building_name, value: items?.uuid })
    })
  }

  const PropertyNameOptions = [{ labe: "Select Property Name", value: "" }]

  if (propertyNameDetails?.data?.length > 0) {
    propertyNameDetails?.data?.map((items) => {
      return PropertyNameOptions?.push({ label: items?.property_building_name, value: items?.uuid })
    })
  }

  const PropertyTypeOptions = []
  if (propertyTypeDetails?.data?.length > 0) {
    propertyTypeDetails?.data?.map((items) => {
      return PropertyTypeOptions?.push({ label: items?.property_building_name, value: items?.uuid })
    })
  }

  const ServiceTypeOptions = []
  if (serviceTypeDetails?.data?.length > 0) {
    serviceTypeDetails?.data?.map((items) => {
      return ServiceTypeOptions?.push({ label: items?.label, value: items?.uuid })
    })
  }

  const HandleChangePropertyName = (event) => {
    console.log(event, "dhfdsghfgdhgshj")
    setPropertyNameOptionValue(event)
    PropertyTypeDropdownFun(event?.value)
  }

  const [typeValue, setTypeValue] = useState('');
  const [unitValue, setUnitValue] = useState('');

  const HandleChangeType = (event) => {
    console.log(event, "dhfdsghfgdhgshj")
    setTypeValue(event)
  }

  const HandleChangeUnit = (event) => {
    console.log(event?.target?.value, "dhfdsghfgdhgshj")
    setUnitValue(event?.target?.value)
    HandleChangeUnitNo(event?.target?.value)
  }

  const HandleChangeServiceName = (event) => {
    setServiceoptionValue(event)
  }

  const HandleChangeDate = (event) => {
    setDateValue(event?.target?.value)
  }
  const HandleChangeTime = (event) => {
    setTimeValue(event?.target?.value)
  }
  const HandleChangeNote = (event) => {
    console.log(event, "ghdfhjdghjsk");
    setNoteValue(event?.target?.value)
  }

  const HandleShowAndHide = (e) => {
    setShowHide(e?.target?.checked)
  }

  const [selectedServices, setSelectedServices] = useState({});

  const HandleChangeUnitNo = (uuid) => {
    axios.get(`${process.env.React_App_Url}/api/v1/getByUnitNumber/${uuid}`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    },
    )
      .then((response) => {
        console.log(response, "asdasdasd");
        setUnitNoData(response?.data)
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      })
  }

  const handleCheckboxChange = (categoryUuid, serviceUuid) => {
    setSelectedServices(prevState => ({
      ...prevState,
      [categoryUuid]: {
        ...prevState[categoryUuid],
        [serviceUuid]: !prevState[categoryUuid]?.[serviceUuid]
      }
    }));
  };

  const toTitleCase = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const AdditionalService = AdditionalpropertyDetails?.data.map(category => ({
      ...category,
      services: category.services.filter(service => selectedServices[category.uuid]?.[service.uuid])
    })).filter(category => category.services.length > 0);

    console.log(AdditionalService, "AdditionalService")

    const payload = {
      consumer_uuid: userData?.user?.uuid,
      unit_uuid: propertyNameoptionvalue?.value,
      service_uuid: serviceoptionvalue?.value,
      booking_date: datevalue,
      booking_time: timevalue,
      notes: notevalue,
      additional_service_deatils: AdditionalService,
      property_type: typeValue?.value,
      unit_number: unitValue,
    };

    axios.post(`${process.env.React_App_Url}/api/v1/booking/create`, payload, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    })
      .then((response) => {
        console.log(response.data.data, "asdasdasd");
        toast.success(response.data, { position: 'top-right' });
        Swal.fire({
          icon: 'success',
          title: response.data?.message,
          text: response.data?.message,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/BookngList')
          }
        });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
        Swal.fire({
          icon: 'error',
          title: error.response.data?.message,
          text: error.response.data?.message,
        })
      })
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="main-wrapper">
      <Header />
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu="BookingDashboard" />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>Booking</h3>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <form onSubmit={handleSubmit}>
                            <div className="row mx-5">
                              <div className="col-md-6 col-item">
                                <div className="form-group">
                                  <label htmlFor="address">Unit Number</label>
                                  <div className="search-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="address"
                                      placeholder="Enter Unit No"
                                      value={unitValue}
                                      onChange={HandleChangeUnit}
                                    // styles={style}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-item">
                                <div className="form-group">
                                  <label htmlFor="address">Property Name</label>
                                  <div className="search-group">
                                    <Select
                                      className=" select"
                                      name="sellist1"
                                      options={UnitNoOptions}
                                      placeholder="Choose"
                                      onChange={HandleChangePropertyName}
                                      value={propertyNameoptionvalue}
                                      styles={style}
                                    ></Select>

                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-item">
                                <div className="form-group">
                                  <label htmlFor="address">Property Type</label>
                                  <div className="search-group">
                                    <Select
                                      className=" select"
                                      name="sellist1"
                                      options={typeDropDown}
                                      placeholder="Choose"
                                      onChange={HandleChangeType}
                                      value={typeValue}
                                      styles={style}
                                    ></Select>

                                  </div>
                                  {/* <div className="form-group select-form mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="address"
                                      placeholder="Enter Property Type"
                                      value={propertyType}
                                    />
                                  </div> */}
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-item">
                                <div className="form-group select-form mb-0">
                                  <label htmlFor="property">Area</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    placeholder="Enter Area"
                                    value={AreaName}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 col-lg-6 col-item">
                                <div className="form-group select-form mb-0">
                                  <label htmlFor="property">Address</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    placeholder="Enter Address"
                                    value={addressValue}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-item">
                                <div className="form-group">
                                  <label htmlFor="address">Service Name</label>
                                  <div className="search-group">
                                    <Select
                                      className=" select"
                                      name="sellist1"
                                      options={ServiceTypeOptions}
                                      placeholder="Choose Service"
                                      onChange={HandleChangeServiceName}
                                      value={serviceoptionvalue}
                                      styles={style}
                                    ></Select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-item">
                                <div className="form-group select-form mb-0">
                                  <label htmlFor="property">Date</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="address"
                                    placeholder="Enter Date"
                                    onChange={HandleChangeDate}
                                    value={datevalue}
                                    min={today}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-item">
                                <div className="form-group select-form mb-0">
                                  <label htmlFor="property">Time</label>
                                  <input
                                    type="time"
                                    className="form-control"
                                    id="address"
                                    placeholder="Enter Time"
                                    onChange={HandleChangeTime}
                                    value={timevalue}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-item mt-4">
                                <div className="form-group select-form mb-0">
                                  <label htmlFor="property">Add Additional Service</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-item mt-4">
                                <div className="form-group select-form mb-0">
                                  <div className="custom-checkbox">
                                    <input
                                      type="checkbox"
                                      id="customCheckbox"
                                      onChange={HandleShowAndHide}
                                    />
                                    {showHide === true && <label htmlFor="customCheckbox" style={{ fontSize: "14px" }}> Hide</label>}
                                    {showHide === false && <label htmlFor="customCheckbox" style={{ fontSize: "14px" }}> Show</label>}
                                  </div>
                                </div>
                              </div>
                              {showHide === true &&
                                <Row>
                                  {AdditionalpropertyDetails?.data?.map((category) => (
                                    <Col xs={12} md={6} key={category.uuid} className='mb-4'>
                                      <h5 style={{ fontSize: "14px" }}>{toTitleCase(category?.name)}</h5>
                                      <Row>
                                        {category.services.map(service => (
                                          <Col xs={12} key={service.uuid} className='mb-2'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <input
                                                type="checkbox"
                                                id={`${category.uuid}-${service.uuid}`}
                                                checked={selectedServices[category.uuid]?.[service.uuid] || false}
                                                onChange={() => handleCheckboxChange(category.uuid, service.uuid)}
                                                style={{ marginRight: '8px' }}
                                              />
                                              <label htmlFor={`${category.uuid}-${service.uuid}`} style={{ fontSize: "14px" }}>
                                                {service?.name[0]?.toUpperCase() + service?.name?.slice(1)}
                                              </label>
                                            </div>
                                          </Col>
                                        ))}
                                      </Row>
                                    </Col>
                                  ))}
                                </Row>
                              }
                              <div className="col-md-12 col-lg-12 col-item">
                                <div className="form-group select-form mb-0">
                                  <label htmlFor="property">Note</label>
                                  <textarea
                                    className="form-control"
                                    id="comments"
                                    placeholder="Enter Note"
                                    value={notevalue}
                                    onChange={HandleChangeNote}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12 col-item mt-3 mb-4">
                                <button type="submit" className="btn btn-primary">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                     
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row mt-3">
                        <div className="col-md-12">
                          <form onSubmit={handleSubmit}>
                            <div className="row mx-5">
                            <div className="col-xs-12 col-md-4 col-item">
                                <p>Property Name : <span>ABC Name</span></p> 
                              </div>

                              <div className="col-xs-12 col-md-4 col-item">
                                <p>Choose Property Type : <span>ABC Name</span></p> 
                              </div>

                              <div className="col-xs-12 col-md-4 col-item">
                                <p>Choose Unit Number : <span>ABC Name</span></p> 
                              </div>

                              <div className="col-xs-12 col-md-4 col-item">
                                <p>Area : <span>ABC Name</span></p> 
                              </div>

                              <div className="col-xs-12 col-md-4 col-item">
                                <p>Address  : <span>ABC Name</span></p> 
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* Instructor Dashboard */}





          </div>
        </div>
      </div >
      {/* Page Wrapper */}
      < Footer />
    </div >
  );
}
