import React from "react";
import InstructorSidebar from "../sidebar";
import Header from "../../header/index-two";
import { Footer } from "../../footer";
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2'
// import axios from "axios";


export default function B2BProfile() {


    return (
        <div className="main-wrapper">
            <Header />
            {/* Page Wrapper */}
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        {/* Sidebar */}
                        <InstructorSidebar activeMenu="Profile" />
                        {/* Sidebar */}

                        {/* Instructor Dashboard */}
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="settings-widget">
                                        <div className="settings-inner-blk p-0">
                                            <div className="sell-course-head comman-space">
                                                <h3>Profile</h3>
                                            </div>
                                            <div className="row mt-4">
                                                <div className="col-md-12">
                                                    <form >
                                                        <div className="row mx-5">
                                                            <div className="col-md-6 col-item">
                                                                <div className="form-group">
                                                                    <label htmlFor="address">First Name</label>
                                                                    <div className="search-group">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="address"
                                                                            placeholder="Enter First Name"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-item">
                                                                <div className="form-group">
                                                                    <label htmlFor="address">Last Name</label>
                                                                    <div className="search-group">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="address"
                                                                            placeholder="Enter Last Name"
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-item">
                                                                <div className="form-group">
                                                                    <label htmlFor="address">Mobile</label>
                                                                    <div className="search-group">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="address"
                                                                            placeholder="Enter Mobile"
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-lg-6 col-item">
                                                                <div className="form-group select-form mb-0">
                                                                    <label htmlFor="property">Email</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="address"
                                                                        placeholder="Enter Emailf"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-item mt-3 mb-4">
                                                                <button type="submit" className="btn btn-primary">
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                     
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row mt-3">
                        <div className="col-md-12">
                          <form onSubmit={handleSubmit}>
                            <div className="row mx-5">
                            <div className="col-xs-12 col-md-4 col-item">
                                <p>Property Name : <span>ABC Name</span></p> 
                              </div>

                              <div className="col-xs-12 col-md-4 col-item">
                                <p>Choose Property Type : <span>ABC Name</span></p> 
                              </div>

                              <div className="col-xs-12 col-md-4 col-item">
                                <p>Choose Unit Number : <span>ABC Name</span></p> 
                              </div>

                              <div className="col-xs-12 col-md-4 col-item">
                                <p>Area : <span>ABC Name</span></p> 
                              </div>

                              <div className="col-xs-12 col-md-4 col-item">
                                <p>Address  : <span>ABC Name</span></p> 
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                        </div>
                        {/* Instructor Dashboard */}





                    </div>
                </div>
            </div >
            {/* Page Wrapper */}
            < Footer />
        </div >
    );
}
