import React from "react";
// import {
//   Course10,
//   Course11,
//   Course12,
//   Course13,
//   Course14,
//   Icon1, 
//   TimerStart,
// } from "../../imagepath";
// import { Search } from "react-feather";
// import { Link } from "react-router-dom";
import InstructorSidebar from "../sidebar";
// import Select from "react-select";
import Header from "../../header/index-two";
import { Footer } from "../../footer";
import './DashboardInstructor.css';

export default function Dashboard() {


  return (
    <div className="main-wrapper">
      <Header/>
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu="DashboardInstructor" />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3 >Dashboard</h3>
                       
                      </div>
                      <div className="row  m-3 mt-3">
                      <div className="col-md-4 d-flex">
  <div className="card instructor-card w-100">
    <div>
      <div className="instructor-inner">
        <h6 style={{color:"black"}}>Completed Order</h6>
        <h4  className="instructor-text-success">150</h4>
        <p style={{color:"black"}}>Earning this month</p>
      </div>
    </div>
  </div>
</div>

                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div>
                      <div className="instructor-inner">
                        <h6 style={{color:"black"}}>Inprogress</h6>
                        <h4 className="instructor-text-success">120</h4>
                        <p style={{color:"black"}}> Inprogress in this month</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div>
                      <div className="instructor-inner">
                        <h6 style={{color:"black"}}>Pending Order</h6>
                        <h4  className="instructor-text-success">40</h4>
                        <p style={{color:"black"}}>Pending Order of this month</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <Footer />
    </div>
  );
}
