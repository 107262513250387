import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Companylogo from "../../assets/img/contactUs/companylogo.png";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

const Header = () => {
  // change header background on scroll
  const [navbar, setNavbar] = useState(false);

  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("userData"));
  console.log(userData, "jdffdgshfdskjhkjds");

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const changeHeaderBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  // Mobile Menu Toggle
  // const [mobileSubMenu5, setMobileSubMenu5] = useState(false);
  const [mobileSubMenu6, setMobileSubMenu6] = useState(false);
  const openMobileMenu = () => {
    document.body.classList.add("menu-opened");
  };
  const hideMobileMenu = () => {
    document.body.classList.remove("menu-opened");
  };

  // const openMobileSubMenu5 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu5(!mobileSubMenu5);
  // };
  const openMobileSubMenu6 = (e) => {
    e.preventDefault();
    setMobileSubMenu6(!mobileSubMenu6);
  };

  const logoutFun = (event) => {
    // localStorage.removeItem('userData')
    // navigate("/login");
    event.preventDefault();
    const payload = {
      user_id: userData?.user?.uuid,
    };
    const apiUrl = `${process.env.React_App_Url}/api/v1/frontend/logout`;
    axios
      .post(apiUrl, payload)
      .then((response) => {
        localStorage.removeItem("userData");
        toast.success(response.data.message, { position: "top-right" });
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
        toast.error("Failed to logout. Please try again.");
      });
  };

  // const RedirectToSubservice = (event) => {
  //   navigate("/HomeCleaning", { state: event })
  // }

  const [serviceTypeDetails, setServiceTypeDetails] = useState("");
  console.log(serviceTypeDetails, "serviceTypeDetails");

  const ServiceTypeFunction = () => {
    axios
      .get(
        `${process.env.React_App_Url}/api/v1/service/type/listForb2cClient`,
        {
          headers: {
            authorization: `Bearer ${userData?.accessToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response, "asdasdasd");
        setServiceTypeDetails(response?.data);
        toast.success(response.data, { position: "top-right" });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      });
  };
  const RedirectToSubservice = (event) => {
    if (userData === null) {
      navigate("/login");
    } else {
      navigate("/HomeCleaning", { state: event });
    }
  };

  useEffect(() => {
    ServiceTypeFunction();
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  window.addEventListener("scroll", changeHeaderBackground);
  return (
    <div className="row m-0 p-0" style={{ backgroundColor: "#000" }}>
      <header className="header-three">
        <div>
          <nav
            className={
              navbar
                ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
                : "navbar navbar-expand-lg header-nav scroll-sticky"
            }
            style={{ height: "90px" }}
          >
            <div className="container">
              <div className="navbar-header">
                <Link id="mobile_btn" to="#;" onClick={openMobileMenu}>
                  <span className="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </Link>
                <Link to="/" className="navbar-brand logo">
                  <img
                    src={Companylogo}
                    className="img-fluid"
                    alt="Logo"
                    style={{ width: "140px", height: "80px" }}
                  />
                  {/* <p style={{ color: "white", fontSize: "22px", fontWeight: "bold" }}>Appyance</p> */}
                </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img
                      src={Companylogo}
                      className="img-fluid"
                      alt="Logo"
                      style={{ width: "140px", height: "60px" }}
                    />
                    {/* <p style={{ color: "white", fontSize: "22px", fontWeight: "bold" }}>Appyance</p> */}
                  </Link>
                  <Link
                    id="menu_close"
                    className="menu-close"
                    to="#;"
                    onClick={hideMobileMenu}
                  >
                    <i className="fas fa-times text-light"></i>
                  </Link>
                </div>
                <ul className="main-nav">
                  <li className="has-submenu menu-effect ">
                    <Link to="/" style={{ color: "white" }}>
                      Home{" "}
                    </Link>
                  </li>
                  <li className="has-submenu menu-effect">
                    <Link
                      style={{ color: "white" }}
                      onClick={openMobileSubMenu6}
                    >
                      Our Services{" "}
                      <i
                        className="fas fa-chevron-down"
                        onClick={openMobileSubMenu6}
                      ></i>
                    </Link>
                    <ul
                      className={
                        mobileSubMenu6 ? "submenu submenuShow" : "submenu"
                      }
                    >
                      {/* <li>
                        <Link to="/HomeCleaning">Home cleaning services</Link>
                      </li>
                      */}
                      {serviceTypeDetails?.data?.length > 0 &&
                        serviceTypeDetails?.data?.map((items, index) => (
                          <li
                            onClick={() => RedirectToSubservice(items)}
                            key={index}
                          >
                            <Link>{items?.label}</Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li className="has-submenu menu-effect ">
                    <a href="https://shop.elitesuperstar.net" target="_blank" rel="noopener noreferrer" style={{ color: "white" }}>
                      Elite Shop{" "}
                    </a>
                  </li>
                  <li className="has-submenu menu-effect ">
                    <Link to="/AboutUs" style={{ color: "white" }}>
                      About us{" "}
                    </Link>
                  </li>

                  <li className="has-submenu menu-effect ">
                    <Link to="/team" style={{ color: "white" }}>
                      Our Team{" "}
                    </Link>
                  </li>
                  <li className="has-submenu menu-effect ">
                    <Link to="/team" style={{ color: "white" }}>
                      B2B Clients{" "}
                    </Link>
                  </li>
                  <ul className="nav header-navbar-rht align-items-center flex-row ">
                    <li className="has-submenu menu-effect">
                      {userData === null ? (
                        <Link
                          style={{ color: "white" }}
                          className="login-head button"
                          to="/login"
                        >
                          Login
                        </Link>
                      ) : (
                        ""
                      )}
                    </li>
                    <li className="has-submenu menu-effect">
                      {userData === null && (
                        <Link
                          style={{ color: "white" }}
                          className="signin-head"
                          to="/register"
                        >
                          Sign up
                        </Link>
                      )}
                    </li>
                    <li className="has-submenu menu-effect d-flex">
                      {userData && userData?.user?.type === "B2B" ? (
                        <>
                          <Link style={{ color: "white" }} to="/Dashboard">
                            Dashboard
                          </Link>
                          &nbsp;
                          <Link
                            style={{ color: "white" }}
                            onClick={logoutFun}
                            className="signin-head button"
                          >
                            Logout
                          </Link>
                        </>
                      ) : userData && userData?.user?.type === "B2C" ? (
                        <>
                          <Link style={{ color: "white" }} to="/B2CDashboard">
                            Dashboard
                          </Link>
                          &nbsp;
                          <Button
                            style={{ color: "white" }}
                            onClick={logoutFun}
                          >
                            Logout
                          </Button>
                        </>
                      ) : userData && userData?.result?.role === "customer" ? (
                        <>
                          <Button
                            style={{ color: "white" }}
                            onClick={logoutFun}
                          >
                            Logout
                          </Button>
                        </>
                      ) : ""}
                    </li>
                  </ul>
                  {/* <li className="has-submenu menu-effect">
                    <Link to="/ContactUs" style={{ color: "white" }}>
                      Contact us{" "}
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
