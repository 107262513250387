import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InstructorSidebar from "../sidebar";
import Header from "../../header/index-two";
import { Footer } from "../../footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import './dashbord.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function AddUnit() {

  const userData = JSON.parse(localStorage.getItem('userData'));

  const [tabIndex, setTabIndex] = useState(0);
  const [UnitData, setUnitData] = useState('')
  console.log(UnitData, "fjkdhsfj");

  useEffect(() => {
    UnitListURL()
  }, [tabIndex])

  const UnitListURL = () => {

    const type = tabIndex === 0 ? 'approved' : 'pending';

    axios.get(`${process.env.React_App_Url}/api/v1/frontend/unitList?page=0&limit=10&search=&type=${type}`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    },
    )
      .then((response) => {
        console.log(response, "asdasdasd");
        setUnitData(response?.data?.data)
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      })
  }
  // const formatPropertyType = (event) => {
  //   return event.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())
  // }

  const formatPropertyType = (type) => {
    const propertyTypeMapping = {
      "studio": "Studio",
      "one_bhk": "1 BHK",
      "two_bhk": "2 BHK",
      "three_bhk": "3 BHK",
      "four_bhk": "4 BHK",
      "five_bhk": "5 BHK",
      "two_bhk_villa": "2 BHK VILLA",
      "three_bhk_villa": "3 BHK VILLA",
      "four_bhk_villa": "4 BHK VILLA",
      "five_bhk_villa": "5 BHK VILLA",
      "office": "6 BHK VILLA",
    };

    return propertyTypeMapping[type?.toLowerCase()] || type?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char?.toUpperCase());
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [viewData, setViewData] = React.useState({});

  const ViewDetailes = (data) => {
    setViewData(data);
    setModalShow(true);
  }

  const HideModal = () => {
    setViewData({});
    setModalShow(false);
  }
  function MyVerticallyCenteredModal(props) {
    console.log(props, "sadasssssss")
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <small>Wifi Details</small>
          <br />
          <strong>{viewData?.wifi_details ? viewData?.wifi_details : 'N/A'}</strong>
          <br />
          <small>Notes</small>
          <br />
          <strong>
            {viewData?.notes ? viewData?.notes : 'N/A'}
          </strong>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={HideModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div className="main-wrapper">
      <Header />
      {JSON.stringify(viewData) !== "{}" &&
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          Data={viewData}
        />
      }
      {/* Page Wrapper */}
      <div className="page-content">
        <div className="container">
          <div className="row">
            {/* Sidebar */}
            <InstructorSidebar activeMenu="Units" />
            {/* Sidebar */}

            {/* Instructor Dashboard */}
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="sell-course-head comman-space">
                <h3>Unit List</h3>
              </div>
              <div className="settings-widget">
                <div className="settings-inner-blk p-0">
                  <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                    <TabList>
                      <Tab>Approved</Tab>
                      <Tab>Pending</Tab>
                    </TabList>

                    <TabPanel>
                      <div className="row mx-4 border-line pb-3">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  {UnitData?.data?.length <= 0 && <strong>No Records Found</strong>}
                                  {UnitData?.data?.map((items, index) => (
                                    <form key={index}>
                                      <div className="row card">
                                        <div className="row p-3">
                                          <div className="col-xs-12 col-md-3 col-item">
                                            <strong style={{ marginTop: 0, paddingTop: 0 }}>Property Name: </strong>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <strong>Property Type: </strong>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <strong>Unit Number: </strong>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <strong>Area: </strong>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <strong>Address: </strong>
                                          </div>
                                          <div className="col-xs-12 col-md-1 col-item">
                                            &nbsp;
                                          </div>
                                          <div className="col-xs-12 col-md-3 col-item" >
                                            <p style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>{items?.property_building_name}</p>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            {items?.unit_details?.map((item, indexKey) => (
                                              <p key={indexKey}>{formatPropertyType(item?.property_type)}</p>
                                            ))}
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            {items?.unit_details?.map((item, indexKey) => (
                                              <p key={indexKey}>{item?.unit_number}</p>
                                            ))}
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <p>{items?.areas?.name}</p>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <p>{items?.address}</p>
                                          </div>
                                          <div className="col-xs-12 col-md-1 col-item">
                                            <Button onClick={() => ViewDetailes(items)}>View</Button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row mx-4 border-line pb-3">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  {UnitData?.data?.length <= 0 && <strong>No Records Found</strong>}
                                  {UnitData?.data?.map((items, index) => (
                                    <form key={index}>
                                      <div className="row card">
                                        <div className="row p-3">
                                          <div className="col-xs-12 col-md-3 col-item">
                                            <strong style={{ marginTop: 0, paddingTop: 0 }}>Property Name: </strong>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <strong>Property Type: </strong>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <strong>Unit Number: </strong>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <strong>Area: </strong>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <strong>Address: </strong>
                                          </div>
                                          <div className="col-xs-12 col-md-1 col-item">
                                            &nbsp;
                                          </div>
                                          <div className="col-xs-12 col-md-3 col-item">
                                            <p>{items?.property_building_name}</p>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            {items?.unit_details?.map((item, indexKey) => (
                                              <p key={indexKey}>{formatPropertyType(item?.property_type)}</p>
                                            ))}
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            {items?.unit_details?.map((item, indexKey) => (
                                              <p key={indexKey}>{item?.unit_number}</p>
                                            ))}
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <p>{items?.areas?.name}</p>
                                          </div>

                                          <div className="col-xs-12 col-md-2 col-item">
                                            <p>{items?.address}</p>
                                          </div>
                                          <div className="col-xs-12 col-md-1 col-item">
                                            <Button onClick={() => ViewDetailes(items)}>View</Button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
            {/* Instructor Dashboard */}
          </div>
        </div>
      </div>
      {/* Page Wrapper */}
      <Footer />
    </div>
  );
}
