import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { Footer } from "../footer";
import Header from "../header/index-two";
import { HomeRightBottom, Skill1, CheckRound1 } from "../imagepath";
import profileImg from "../../assets/img/team/profile-img.jpg";
const Team = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);
  return (
    <>
      <div className="main-wrapper">
        <Header />
        <div className="page-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h1 className="mb-0">Our Team</h1>
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="container">
            {/* Grow up your skill section */}
            <section className="growup-section">
              <div className="home-two-shapes">
                <img src={HomeRightBottom} alt="" />
              </div>
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-xl-6 col-lg-6 col-md-5 col-12"
                    data-aos="fade-right"
                  >
                    <div className="growup-images-two">
                      <div className="growup-skills-img">
                        <img
                          className=""
                          src={Skill1}
                          alt="image-banner"
                          title="image-banner"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-xl-6 col-lg-6 col-md-7 col-12 wow fadeInLeft"
                    data-aos="fade-left"
                  >
                    <div className="header-two-title">
                      <p className="tagline">Team Principles</p>
                      <h2 className="text-navy">
                        Our Commitment to Our Valued Customers{" "}
                      </h2>
                      <div className="award-list d-flex align-items-start">
                        <span className="award-icon">
                          <img src={CheckRound1} alt="" className="" />
                        </span>
                        <div className="">
                          <strong>
                            Our Team at Elite Superstar Cleaning Services in
                            Dubai
                          </strong>
                          <p>
                            At Elite Superstar Cleaning Services, our team is
                            the cornerstone of our success. Comprising highly
                            trained and experienced professionals, we are
                            dedicated to delivering exceptional cleaning
                            services to our valued clients in Dubai.
                          </p>
                        </div>
                      </div>
                      <div className="award-list d-flex align-items-start">
                        <span className="award-icon">
                          <img src={CheckRound1} alt="" className="" />
                        </span>
                        <div className="">
                          <strong>Experienced and Skilled Professionals</strong>
                          <p>
                            Our team consists of seasoned cleaning experts who
                            are well-versed in the latest cleaning techniques
                            and technologies. Each member undergoes rigorous
                            training to ensure they meet our high standards of
                            service and quality. Their expertise enables them to
                            handle a wide range of cleaning tasks, from regular
                            housekeeping to deep cleaning and specialized
                            services.
                          </p>
                        </div>
                      </div>
                      <div className="award-list d-flex align-items-start">
                        <span className="award-icon">
                          <img src={CheckRound1} alt="" className="" />
                        </span>
                        <div className="">
                          <strong>Commitment to Excellence</strong>
                          <p>
                            We pride ourselves on our meticulous attention to
                            detail and unwavering commitment to excellence. Our
                            team approaches every job with a dedication to
                            achieving the best possible results, ensuring that
                            your home or office is spotless and hygienic. We use
                            eco-friendly and high-quality cleaning products to
                            protect your environment and provide a safe, clean
                            space.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="about-button more-details">
                      <Link to="/register" className="discover-btn">
                        Join Our Team <i className="fas fa-arrow-right ms-2"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Grow up your skill section */}

            <div className="row m-0 p-0 mt-5">
              <h2 className="text-center fw-bold">Our Team Members</h2>
              {/* Repeat the same structure for other columns */}
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 mt-3 mb-3">
                <div
                  className="rounded-4 m-0 p-2 border border-1"
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <div
                    className="course-box-five d-flex align-items-center justify-content-center"
                    style={{ backgroundImage: `url(${profileImg})` }}
                  >
                    <div className="joing-course-ovelay">
                      <p className="text-light text-center">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Suscipit quibusdam provident iusto illo! Quaerat
                        veritatis perspiciatis iusto nemo dolorum asperiores
                        officia, magni molestiae et aperiam debitis laudantium,
                        optio natus distinctio.
                      </p>
                    </div>
                  </div>
                  <div className="w-100 mt-0 p-0 d-flex flex-column align-items-center justify-content-center">
                    <h5 className="">Sharvan Kumar</h5>
                    <p className="text-secondary fw-semibold">CEO</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 mt-3 mb-3">
                <div
                  className="rounded-4 m-0 p-2 border border-1"
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <div
                    className="course-box-five d-flex align-items-center justify-content-center"
                    style={{ backgroundImage: `url(${profileImg})` }}
                  >
                    <div className="joing-course-ovelay">
                      <p className="text-light text-center">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Suscipit quibusdam provident iusto illo! Quaerat
                        veritatis perspiciatis iusto nemo dolorum asperiores
                        officia, magni molestiae et aperiam debitis laudantium,
                        optio natus distinctio.
                      </p>
                    </div>
                  </div>
                  <div className="w-100 mt-0 p-0 d-flex flex-column align-items-center justify-content-center">
                    <h5 className="">Sharvan Kumar</h5>
                    <p className="text-secondary fw-semibold">CEO</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 mt-3 mb-3">
                <div
                  className="rounded-4 m-0 p-2 border border-1"
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <div
                    className="course-box-five d-flex align-items-center justify-content-center"
                    style={{ backgroundImage: `url(${profileImg})` }}
                  >
                    <div className="joing-course-ovelay">
                      <p className="text-light text-center">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Suscipit quibusdam provident iusto illo! Quaerat
                        veritatis perspiciatis iusto nemo dolorum asperiores
                        officia, magni molestiae et aperiam debitis laudantium,
                        optio natus distinctio.
                      </p>
                    </div>
                  </div>
                  <div className="w-100 mt-0 p-0 d-flex flex-column align-items-center justify-content-center">
                    <h5 className="">Sharvan Kumar</h5>
                    <p className="text-secondary fw-semibold">CEO</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 mt-3 mb-3">
                <div
                  className="rounded-4 m-0 p-2 border border-1"
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <div
                    className="course-box-five d-flex align-items-center justify-content-center"
                    style={{ backgroundImage: `url(${profileImg})` }}
                  >
                    <div className="joing-course-ovelay">
                      <p className="text-light text-center">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Suscipit quibusdam provident iusto illo! Quaerat
                        veritatis perspiciatis iusto nemo dolorum asperiores
                        officia, magni molestiae et aperiam debitis laudantium,
                        optio natus distinctio.
                      </p>
                    </div>
                  </div>
                  <div className="w-100 mt-0 p-0 d-flex flex-column align-items-center justify-content-center">
                    <h5 className="">Sharvan Kumar</h5>
                    <p className="text-secondary fw-semibold">CEO</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 mt-3 mb-3">
                <div
                  className="rounded-4 m-0 p-2 border border-1"
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <div
                    className="course-box-five d-flex align-items-center justify-content-center"
                    style={{ backgroundImage: `url(${profileImg})` }}
                  >
                    <div className="joing-course-ovelay">
                      <p className="text-light text-center">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Suscipit quibusdam provident iusto illo! Quaerat
                        veritatis perspiciatis iusto nemo dolorum asperiores
                        officia, magni molestiae et aperiam debitis laudantium,
                        optio natus distinctio.
                      </p>
                    </div>
                  </div>
                  <div className="w-100 mt-0 p-0 d-flex flex-column align-items-center justify-content-center">
                    <h5 className="">Sharvan Kumar</h5>
                    <p className="text-secondary fw-semibold">CEO</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 mt-3 mb-3">
                <div
                  className="rounded-4 m-0 p-2 border border-1"
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <div
                    className="course-box-five d-flex align-items-center justify-content-center"
                    style={{ backgroundImage: `url(${profileImg})` }}
                  >
                    <div className="joing-course-ovelay">
                      <p className="text-light text-center">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Suscipit quibusdam provident iusto illo! Quaerat
                        veritatis perspiciatis iusto nemo dolorum asperiores
                        officia, magni molestiae et aperiam debitis laudantium,
                        optio natus distinctio.
                      </p>
                    </div>
                  </div>
                  <div className="w-100 mt-0 p-0 d-flex flex-column align-items-center justify-content-center">
                    <h5 className="">Sharvan Kumar</h5>
                    <p className="text-secondary fw-semibold">CEO</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 mt-3 mb-3">
                <div
                  className="rounded-4 m-0 p-2 border border-1"
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <div
                    className="course-box-five d-flex align-items-center justify-content-center"
                    style={{ backgroundImage: `url(${profileImg})` }}
                  >
                    <div className="joing-course-ovelay">
                      <p className="text-light text-center">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Suscipit quibusdam provident iusto illo! Quaerat
                        veritatis perspiciatis iusto nemo dolorum asperiores
                        officia, magni molestiae et aperiam debitis laudantium,
                        optio natus distinctio.
                      </p>
                    </div>
                  </div>
                  <div className="w-100 mt-0 p-0 d-flex flex-column align-items-center justify-content-center">
                    <h5 className="">Sharvan Kumar</h5>
                    <p className="text-secondary fw-semibold">CEO</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6 mt-3 mb-3">
                <div
                  className="rounded-4 m-0 p-2 border border-1"
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                >
                  <div
                    className="course-box-five d-flex align-items-center justify-content-center"
                    style={{ backgroundImage: `url(${profileImg})` }}
                  >
                    <div className="joing-course-ovelay">
                      <p className="text-light text-center">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Suscipit quibusdam provident iusto illo! Quaerat
                        veritatis perspiciatis iusto nemo dolorum asperiores
                        officia, magni molestiae et aperiam debitis laudantium,
                        optio natus distinctio.
                      </p>
                    </div>
                  </div>
                  <div className="w-100 mt-0 p-0 d-flex flex-column align-items-center justify-content-center">
                    <h5 className="">Sharvan Kumar</h5>
                    <p className="text-secondary fw-semibold">CEO</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Team;
