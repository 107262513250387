import React, { useState, useEffect } from "react";
import InstructorSidebar from "../sidebar";
import { Footer } from "../../footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Select from "react-select";
import moment from "moment/moment";
import "./dashbord.css"
import Modal from 'react-bootstrap/Modal';
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';

export default function InvoiceDashboard() {

  const userData = JSON.parse(localStorage.getItem('userData'));

  const [InvoiceData, setInvoiceData] = useState('');
  const [offlinedata, setOfflineData] = useState('');
  const [PaymentDetails, setPaymentDetails] = useState('');
  const [open, setOpen] = useState(false);
  const [showOnlineModal, setShowOnlineModal] = useState(false);
  const [showOfflineModal, setShowOfflineModal] = useState(false);
  const [PaymentDetailsOpen, setPaymentDetailsopen] = useState(false);
  console.log(InvoiceData, PaymentDetails, "InvoiceData");

  const [Typevalue, setTypeValue] = useState('')
  const [transactionid, setTransactionId] = useState('')
  const [Image, setImage] = useState('')

  const [InvoiceNumber, setInvoiceNumber] = useState("");
  console.log(InvoiceNumber, offlinedata, "gjfdhfkjdsds");

  const navigate = useNavigate();

  const Options = [
    { label: "Bank Transfer", value: "bank transfer" },
    { label: "By Cheque", value: "by cheque" }
  ]

  const HandleChangeType = (e) => {
    setTypeValue(e)
  }

  const HandleChangeTransaction = (e) => {
    setTransactionId(e?.target?.value)
  }

  useEffect(() => {
    InvoiceListURL();
  }, []);

  const InvoiceListURL = () => {
    axios.get(`${process.env.React_App_Url}/api/v1/frontend/invoiceList?page=0&limit=30&type=pending&from_date=&to_date&search=`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    })
      .then((response) => {
        console.log(response, "asdasdasd");
        setInvoiceData(response?.data?.data);
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      });
  };

  const handleFileattach = (e) => {
    const selectedfile = e.target.files[0]
    console.log(selectedfile, "selectedfile");
    const formData = new FormData()
    formData.append('file', selectedfile)
    axios
      .post(`${process.env.React_App_Url}/api/v1/upload/image`, formData, {
        headers: {
          authorization: `Bearer ${userData?.accessToken}`,
        },
      })
      .then((res) => {
        console.log(res.data, 'resresresres')
        if (res.data?.statusCode === 200) {
          setImage(res.data.data.image.filename)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
  }


  const InvoiceStatusListURL = (e) => {
    e.preventDefault();
    const payload = {
      "type": Typevalue?.value,
      "transaction_id": transactionid,
      "upoload_file": Image
    }
    axios.put(`${process.env.React_App_Url}/api/v1/uploadPaymentDetails/${InvoiceNumber}`, payload, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    })
      .then((response) => {
        console.log(response, "asdasdasd");
        setOfflineData(response?.data?.data);
        setOpen(false);
        setShowOfflineModal(false);

        Swal.fire({
          icon: 'success',
          title: response.data?.message,
          text: response.data?.message,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/InvoiceDashboard')
            InvoiceListURL();
          }
        });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      });
  };

  const HandlePaymentOpen = (event) => {
    setOpen(true)
    setInvoiceNumber(event?.uuid)
  }

  const HandleCloseOpen = () => {
    setOpen(false)
  }

  const handleOnlinePayment = () => {
    setShowOnlineModal(true);
  }

  const handleOfflinePayment = () => {
    setShowOfflineModal(true);
  }

  const handleCloseOnlineModal = () => {
    setShowOnlineModal(false);
  }

  const handleCloseOfflineModal = () => {
    setShowOfflineModal(false);
  }

  const HandleClosePaymentDetailsOpen = () => {
    setPaymentDetailsopen(false)
  }

  const HandlePaymentDetails = (event) => {
    setPaymentDetailsopen(true);
    axios.get(`${process.env.React_App_Url}/api/v1/paymentDetails/${event?.uuid}`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    })
      .then((response) => {
        console.log(response, "asdasdasd");
        setPaymentDetails(response?.data?.data);
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        console.log(error, "sdaassda");
        toast.error(error.response.data);
      });
  }

  return (
    <div className="main-wrapper">
      <div className="page-content">
        <div className="container">
          <div className="row">
            <InstructorSidebar activeMenu="InvoiceDashboard" />
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <div className="sell-course-head comman-space">
                    <h3>Invoice</h3>
                  </div>
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="row mx-4">
                        {InvoiceData?.data?.length <= 0 && <strong>No Records Found</strong>}
                        {InvoiceData?.data?.map((items, index) => {
                          return (
                            <div key={index} className="col-md-12">
                              <div className="card mt-3">
                                <div className="row p-3">
                                  <div className="col-xs-12 col-md-2 col-item">
                                    <strong>From Date</strong>
                                    <p>{moment(items?.booking_date_from).format("YYYY-MM-DD")}</p>
                                  </div>
                                  <div className="col-xs-12 col-md-2 col-item">
                                    <strong>To Date</strong>
                                    <p>{moment(items?.booking_date_to).format("YYYY-MM-DD")}</p>
                                  </div>
                                  <div className="col-xs-12 col-md-2 col-item">
                                    <strong>Amount</strong>
                                    <p>{items?.amount}</p>
                                  </div>
                                  <div className="col-xs-12 col-md-2 col-item">
                                    <strong>Invoice Number</strong>
                                    <p>{items?.invoice_number}</p>
                                  </div>
                                  <div className="col-xs-12 col-md-2 col-item">
                                    <strong>Payment Status</strong>
                                    <p>{items?.payment_status === "pending" ? "Pending" : "Paid"}</p>
                                  </div>
                                  <div className="col-xs-12 col-md-2 col-item">
                                    <strong>Action</strong>
                                    {items?.payment_details ?
                                      <button type="submit" className="btn btn-primary" onClick={() => HandlePaymentDetails(items)}>Payment Details</button>
                                      :
                                      <button type="submit" className="btn btn-primary" onClick={() => HandlePaymentOpen(items)}>Pay Now</button>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={open}
        onHide={HandleCloseOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Card.Title>Online Payment</Card.Title>
                  <Card.Text>
                    Pay online using your credit/debit card or through net banking.
                  </Card.Text>
                  <Button variant="primary" onClick={handleOnlinePayment}>Pay Online</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Card.Title>Offline Payment</Card.Title>
                  <Card.Text>
                    Pay offline through bank transfer or by visiting our office.
                  </Card.Text>
                  <Button variant="secondary" onClick={handleOfflinePayment}>Pay Offline</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={HandleCloseOpen}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* Online Payment Modal */}
      <Modal
        show={showOnlineModal}
        onHide={handleCloseOnlineModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Online Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Coming Soon</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseOnlineModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* Offline Payment Modal */}
      <Modal
        show={showOfflineModal}
        onHide={handleCloseOfflineModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Offline Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={InvoiceStatusListURL}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Type</Form.Label>
              <Select
                className="select"
                options={Options}
                placeholder="Choose"
                value={Typevalue}
                onChange={HandleChangeType}
              ></Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Transaction Id</Form.Label>
              <Form.Control type="text" value={transactionid} onChange={HandleChangeTransaction} placeholder="Enter Transaction Id" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label>Image</Form.Label>
              <Form.Control type="file" onChange={handleFileattach} />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseOfflineModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={PaymentDetailsOpen}
        onHide={HandleClosePaymentDetailsOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Deatils</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <div style={{ marginLeft: "35%" }}>
                <labe><strong>Transaction Id :</strong></labe>
                <span>{PaymentDetails?.payment_details?.transaction_id}</span><br />
                <labe><strong>Type :</strong></labe>
                <span>{PaymentDetails?.payment_details?.type}</span><br />
              </div>
              <div>
                <img src={PaymentDetails?.payment_details?.upoload_file} crossOrigin="anonymous" alt="" style={{ width: "100%", height: "100%" }} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={HandleClosePaymentDetailsOpen}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Footer />
    </div >
  );
}
