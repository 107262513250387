import React, { useState } from "react";
// import { Link } from "react-router-dom";
import Header from "../header/index-two";
import { Footer } from "../footer";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import cleaner from "../../assets/img/contactUs/cleaner.png";
import clock from "../../assets/img/contactUs/clock.png";
import yes from "../../assets/img/contactUs/tick-mark.png";
import no from "../../assets/img/contactUs/error.png";
// import pic1 from '../../assets/img/contactUs/env1.png'
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
// import Box from '@mui/material/Box';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { TextField } from "@mui/material";

const steps = ["Service", "Location", "Date and Time", "Pay and Confirmation"];

const Booking = () => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (cardIndex) => {
    setSelectedCard(cardIndex);
  };

  const cards = [
    { id: 1, description: "Cleaner 1" },
    { id: 2, description: "Cleaner 2" },
    { id: 3, description: "Cleaner 3" },
  ];

  const [selectedCard1, setSelectedCard1] = useState(null);

  const handleCardClick1 = (cardIndex1) => {
    setSelectedCard1(cardIndex1);
  };

  const cards1 = [
    { id: 1, description: "2 Hours" },
    { id: 2, description: "3 Hours" },
    { id: 3, description: "4 Hours" },
  ];

  const [selectedCard2, setSelectedCard2] = useState(null);

  const handleCardClick2 = (cardIndex2) => {
    setSelectedCard2(cardIndex2);
  };

  const cards2 = [
    { id: 1, description: "Yes", image: yes },
    { id: 2, description: "No", image: no },
  ];

  const [selectedCard3, setSelectedCard3] = useState(null);

  const handleCardClick3 = (cardIndex3) => {
    setSelectedCard3(cardIndex3);
  };

  const cards3 = [
    { id: 1, description: "Yes", image: yes },
    { id: 2, description: "No", image: no },
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderStepContent = (step) => {
    const [value, setValue] = React.useState(dayjs("2022-04-17T15:30"));

    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    switch (step) {
      case 0:
        return (
          <Typography>
            <div className="container py-4 disclaimer">
              <div className="container">
                <div className="ux-design-five">
                  <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                      <div className="col-xl-5 col-lg-4 col-md-4 col-sm-12 mb-5">
                        <p
                          style={{
                            fontSize: "22px",
                            fontWeight: "600",
                            color: "#392C7D",
                          }}
                        >
                          Please Select Your Property
                        </p>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select Property
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={age}
                              label="Select Property"
                              onChange={handleChange}
                            >
                              <MenuItem value={10}>1 BHK</MenuItem>
                              <MenuItem value={20}>2 BHK</MenuItem>
                              <MenuItem value={30}>3 BHK</MenuItem>
                              <MenuItem value={40}>4 BHK</MenuItem>
                              <MenuItem value={50}>5 BHK</MenuItem>
                              <MenuItem value={60}>STUDIO</MenuItem>
                              <MenuItem value={70}>2 BHK VILLA</MenuItem>
                              <MenuItem value={80}>3 BHK VILLA</MenuItem>
                              <MenuItem value={90}>4 BHK VILLA</MenuItem>
                              <MenuItem value={100}>5 BHK VILLA</MenuItem>
                              <MenuItem value={110}>OFFICE</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                      <div className="row">
                        <p
                          style={{
                            fontSize: "22px",
                            fontWeight: "600",
                            color: "#392C7D",
                          }}
                        >
                          Required Number of Cleaners
                        </p>
                        {cards.map((card, index) => (
                          <div
                            className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                            key={card.id}
                          >
                            <div
                              className="course-box-five"
                              style={{
                                padding: "20px",
                                color: selectedCard === index ? "#fff" : "#000", // Change background color if selected

                                backgroundColor:
                                  selectedCard === index ? "#392C7D" : "#fff", // Change background color if selected
                                cursor: "pointer",
                                border:
                                  selectedCard === index
                                    ? "2px solid #392C7D"
                                    : "2px solid transparent", // Add border to selected card
                              }}
                              onClick={() => handleCardClick(index)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <img
                                    className=""
                                    alt=""
                                    src={cleaner}
                                    style={{ width: "70px" }}
                                  />
                                </div>
                                <div className="product-content-five p-0">
                                  <p style={{ fontSize: "21px" }}>
                                    {card.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="row">
                        <p
                          style={{
                            fontSize: "22px",
                            fontWeight: "600",
                            color: "#392C7D",
                          }}
                        >
                          For How Many Hours?
                        </p>
                        {cards1.map((card, index) => (
                          <div
                            className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                            key={card.id}
                          >
                            <div
                              className="course-box-five"
                              style={{
                                padding: "20px",
                                color:
                                  selectedCard1 === index ? "#fff" : "#000", // Change background color if selected

                                backgroundColor:
                                  selectedCard1 === index ? "#392C7D" : "#fff", // Change background color if selected
                                cursor: "pointer",
                                border:
                                  selectedCard1 === index
                                    ? "2px solid #392C7D"
                                    : "2px solid transparent", // Add border to selected card
                              }}
                              onClick={() => handleCardClick1(index)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <img
                                    className=""
                                    alt=""
                                    src={clock}
                                    style={{ width: "50px" }}
                                  />
                                </div>
                                <div className="product-content-five p-0">
                                  <p style={{ fontSize: "21px" }}>
                                    {card.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="row">
                        <p
                          style={{
                            fontSize: "22px",
                            fontWeight: "600",
                            color: "#392C7D",
                          }}
                        >
                          Need Cleaning Tools?
                        </p>
                        {cards2.map((card, index) => (
                          <div
                            className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                            key={card.id}
                          >
                            <div
                              className="course-box-five"
                              style={{
                                padding: "20px",
                                color:
                                  selectedCard2 === index ? "#fff" : "#000", // Change background color if selected

                                backgroundColor:
                                  selectedCard2 === index ? "#392C7D" : "#fff", // Change background color if selected
                                cursor: "pointer",
                                border:
                                  selectedCard2 === index
                                    ? "2px solid #392C7D"
                                    : "2px solid transparent", // Add border to selected card
                              }}
                              onClick={() => handleCardClick2(index)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <img
                                    className=""
                                    alt=""
                                    src={card.image}
                                    style={{ width: "50px" }}
                                  />
                                </div>
                                <div className="product-content-five p-0">
                                  <p style={{ fontSize: "21px" }}>
                                    {card.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="row">
                        <p
                          style={{
                            fontSize: "22px",
                            fontWeight: "600",
                            color: "#392C7D",
                          }}
                        >
                          Ironing to Do?
                        </p>
                        {cards3.map((card, index) => (
                          <div
                            className="col-xl-4 col-lg-4 col-md-4 col-sm-12"
                            key={card.id}
                          >
                            <div
                              className="course-box-five"
                              style={{
                                padding: "20px",
                                color:
                                  selectedCard3 === index ? "#fff" : "#000", // Change background color if selected

                                backgroundColor:
                                  selectedCard3 === index ? "#392C7D" : "#fff", // Change background color if selected
                                cursor: "pointer",
                                border:
                                  selectedCard3 === index
                                    ? "2px solid #392C7D"
                                    : "2px solid transparent", // Add border to selected card
                              }}
                              onClick={() => handleCardClick3(index)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                }}
                              >
                                <div>
                                  <img
                                    className=""
                                    alt=""
                                    src={card.image}
                                    style={{ width: "50px" }}
                                  />
                                </div>
                                <div className="product-content-five p-0">
                                  <p style={{ fontSize: "21px" }}>
                                    {card.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Address here  */}
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mt-5">
                      <div
                        className="course-box-five"
                        style={{
                          padding: "20px",
                          color: "#fff", // Change background color if selected

                          backgroundColor: "#392C7D", // Change background color if selected
                          cursor: "pointer",
                          border: "2px solid #fff",
                          textAlign: "center",
                        }}
                      >
                        <div>
                          <p>Your Booking Details</p>
                          <p>Normal Cleaning</p>
                          <p>
                            Cleaners : <span>1</span>
                          </p>
                          <p>
                            Hours : <span>3</span>
                          </p>
                          <p>
                            Cleaning tools : <span>No</span>
                          </p>
                          <p>
                            Ironing : <span>No</span>
                          </p>
                          <p>
                            Total : <span>AED 210</span>
                          </p>
                          <p className="signin-head" onClick={handleNext}>
                            Continue
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Typography>
        );
      case 1:
        return (
          <form className="mt-4">
            <p>Please write here your location</p>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="firstName"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Number"
                    name="lastName"
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Country"
                    name="country"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Pin code"
                    name="PinCode"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Write here full address"
                    rows={4}
                    name="message"
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="btn-submit p-1 mb-3"
              onClick={handleNext}
            >
              {" "}
              Submit
            </button>
          </form>
        );
      case 2:
        return (
          <>
            {" "}
            {/* <div className="container mt-5"> */}
            {/* <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0"> */}
            <Row className="mt-5">
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style={{ display: "flex", justifyContent: "start" }}
              >
                <div className="mt-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={"Select your date"}
                      views={["year", "month", "day"]}
                    />
                  </LocalizationProvider>
                </div>
                &nbsp;&nbsp;
                <div className="mt-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker", "TimePicker"]}>
                      <TimePicker
                        label="Start Time"
                        defaultValue={dayjs("2022-04-17T15:30")}
                      />
                      <TimePicker
                        label="End Time"
                        value={value}
                        onChange={(newValue) => setValue(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </Col>
            </Row>
            {/* </div> */}
            {/* </div>
            </div> */}
          </>
        );
      case 3:
        return (
          <>
            <div className="container">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-5">
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mt-5">
                      <div
                        className="course-box-five"
                        style={{
                          padding: "20px",
                          color: "#fff", // Change background color if selected

                          backgroundColor: "#392C7D", // Change background color if selected
                          cursor: "pointer",
                          border: "2px solid #fff",
                          textAlign: "start",
                        }}
                      >
                        <div>
                          <p>Your Booking Details</p>
                          <p>Normal Cleaning</p>
                          <p>
                            Cleaners : <span>1</span>
                          </p>
                          <p>
                            Date and Time : <span>01/06/2024 12:00 pm</span>
                          </p>
                          <p>
                            Customize notes :{" "}
                            <span>
                              <div className="form-group mt-3">
                                <textarea
                                  className="form-control"
                                  placeholder="Write here..."
                                  rows={3}
                                  name="message"
                                />
                              </div>
                            </span>
                          </p>
                          <p>
                            Total : <span>AED 210</span>
                          </p>
                          <p>
                            5% VAT (included) : <span>AED 36.98</span>
                          </p>
                          <div
                            style={{ display: "flex", justifyContent: "start" }}
                          >
                            <div>
                              <p>Payment type : </p>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div className="remember-me">
                              <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                                <p style={{ color: "white" }}>Cash</p>

                                <input type="checkbox" name="radio" />
                                <span className="checkmark" />
                              </label>
                            </div>
                            &nbsp;&nbsp;
                            <div className="remember-me">
                              <label className="custom_check mr-2 mb-0 d-inline-flex">
                                <p style={{ color: "white" }}> Credit card</p>
                                <input type="checkbox" name="radio" />
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>

                          <div
                            style={{ display: "flex", justifyContent: "start" }}
                            className="mb-2"
                          >
                            <div>
                              <p>Promo Code :</p>
                            </div>
                            &nbsp;&nbsp;
                            <div>
                              <textarea
                                className="form-control"
                                placeholder="Write here..."
                                rows={1}
                                name="message"
                              />
                            </div>
                            &nbsp;&nbsp;
                            <div>
                              <button
                                className="joing-course-btn"
                                type="submit"
                              >
                                Apply
                              </button>
                            </div>
                          </div>

                          <NavLink to="/">
                            <p className="signin-head">Continue</p>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return "Unknown step";
    }
  };
  return (
    <>
      <Header />
      <div className="main-wrapper " style={{ marginTop: "100px" }}>
        <div className="page-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h1 className="mb-0">Booking</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "60px" }}>
        <div className="row">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {/* <Typography sx={{ mt: 2, mb: 1 }}>
                  Step {activeStep + 1}
                </Typography> */}
                {renderStepContent(activeStep)}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                      Skip
                    </Button>
                  )}

                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? " " : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Booking;
