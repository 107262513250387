import React from "react";

const SalarySlip = () => {
    return (
        <>








            <div style={{paddingTop: "50px", paddingBottom: "50px", background: "#b79205"}}>
                <div className="container bootstrap snippets bootdey" style={{width: "90%", margin: "0 auto"}}>
                    <div style={{
                        background: "#FFF",
                        border: "1px solid #CDD3E2",
                        boxShadow: "0px 0px 1px #CCC",
                        marginTop: "40px",
                        borderRadius: "4px"
                    }}>
                        <div style={{padding: "20px", backgroundColor: "white"}}>
                            <table style={{
                                width: "100%",
                                textAlign: "center",
                                border: "1px solid black",
                                borderCollapse: "collapse",
                                tableLayout: "fixed"
                            }}>
                                <tr height="80px" style={{borderBottom: "1px solid black", backgroundColor: "#00839b", color: "white"}}>
                                    <td colSpan='12'>
                                        <h3 style={{color: "white"}}>ELITE SUPERSTAR HOME & SHORT-TERM RENTAL SERVICE</h3>
                                    </td>
                                </tr>

                                <tr height="50px" style={{borderBottom: "1px solid black", backgroundColor: "#f2f8f9"}}>
                                    <td colSpan='12'>
                                        <div>PAYSLIP FOR MONTH OF APRIL 2024</div>
                                    </td>
                                </tr>

                                <tr height="50px" style={{borderBottom: "1px solid black", backgroundColor: "#f2f8f9"}}>
                                    <td colSpan='12'>
                                        <div>EMPLOYEE DETAILS</div>
                                    </td>
                                </tr>

                                <tbody style={{textAlign: "center"}}>
                                    <tr>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>EMP NAME</td>
                                        <th colSpan='3' style={{border: "1px solid black", padding: "8px"}}>LALIT KUMAR</th>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>PAY PERIOD</td>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>APRIL 1-30, 2024</td>
                                    </tr>
                                    <tr>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>DESIGNATION</td>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>CLEANER</td>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>PAY DATE</td>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>3 MAY 2024</td>
                                    </tr>
                                    <tr>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>SALARY RATE</td>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>66.67</td>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>PREPARED BY: AAYESA JONESA COLUMNA HR MANAGER</td>
                                    </tr>
                                    <tr>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>TOTAL NUMBER OF DAYS PRESENT</td>
                                        <td colSpan='3' style={{border: "1px solid black", padding: "8px"}}>25</td>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>PREPARED BY: AAYESA JONESA COLUMNA HR MANAGER</td>
                                    </tr>

                                    <tr height="50px" style={{borderBottom: "1px solid black", backgroundColor: "#f2f8f9"}}>
                                        <td colSpan='12'>
                                            <div>EARNINGS</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px"}}>DESCRIPTIONS</th>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px"}}>AMOUNT</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>DAYS OFF</td>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px"}}>333</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>MD INCENTIVES</td>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px"}}>50</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>OVERTIME</td>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px"}}>1447</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>TOTAL SALARY</td>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>1667</td>
                                    </tr>
                                    <tr>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px", backgroundColor: "#d9edf1"}}>TOTAL EARNING</th>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px", backgroundColor: "#d9edf1"}}>2485</th>
                                    </tr>

                                    <tr height="50px" style={{borderBottom: "1px solid black", backgroundColor: "#f2f8f9"}}>
                                        <td colSpan='12'>
                                            <div>DEDUCTION</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px"}}>DESCRIPTIONS</th>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px"}}>AMOUNT</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>ABSENT</td>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px"}}>0</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>LOAN DEDUCTION</td>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px"}}>660</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>VIOLATION DEDUCTION</td>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px"}}>0</th>
                                    </tr>
                                    <tr>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>TOTAL DEDUCTION</td>
                                        <td colSpan="6" style={{border: "1px solid black", padding: "8px"}}>660</td>
                                    </tr>
                                    <tr>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px", backgroundColor: "#d9edf1"}}>TOTAL NET SALARY AMOUNT</th>
                                        <th colSpan="6" style={{border: "1px solid black", padding: "8px", backgroundColor: "#d9edf1"}}>660</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalarySlip;
