import React, { useState, useEffect } from "react";
// import {
//   Course10,
//   Course11,
//   Course12,
//   Course13,
//   Course14,
//   Icon1, 
//   TimerStart,
// } from "../../imagepath";
// import { Search } from "react-feather";
// import { Link } from "react-router-dom";
import B2CInstructorSidebar from "../B2CSidebar";
// import Select from "react-select";
import Header from "../../header/index-two";
import { Footer } from "../../footer";
import './B2CDashboard.css';
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function B2CDashboard() {

    const [Dashboarddata, serDashboarddata] = useState('')
    console.log(Dashboarddata, "gefjdjfhdsjhskj");

    const userData = JSON.parse(localStorage.getItem('userData'));

    const B2CDashboardUrl = () => {
        axios.get(`${process.env.React_App_Url}/api/v1/b2clientdashboard`, {
            headers: {
                authorization: `Bearer ${userData?.accessToken}`,
            },
        })
            .then((response) => {
                console.log(response, "bharath123");
                serDashboarddata(response?.data)
                toast.success(response.data, { position: 'top-right' });
            })
            .catch((error) => {
                console.log(error, "sdaassda");
                toast.error(error.response.data);
            })
    }

    useEffect(() => {
        B2CDashboardUrl()
    }, [])


    return (
        <div className="main-wrapper">
            <Header />
            {/* Page Wrapper */}
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        {/* Sidebar */}
                        <B2CInstructorSidebar activeMenu="DashboardInstructor" />
                        {/* Sidebar */}

                        {/* Instructor Dashboard */}
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="settings-widget">
                                        <div className="settings-inner-blk p-0">
                                            <div className="sell-course-head comman-space">
                                                <h3 >Dashboard</h3>

                                            </div>
                                            <div className="row  m-3 mt-3">
                                                <div className="col-md-4 d-flex">
                                                    <div className="card instructor-card w-100">
                                                        <div>
                                                            <div className="instructor-inner">
                                                                <h6 style={{ color: "black" }}>Completed</h6>
                                                                <h4 className="instructor-text-success">{Dashboarddata && Dashboarddata?.data && Dashboarddata?.data[0]?.completed}</h4>
                                                                {/* <p style={{ color: "black" }}>Earning this month</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 d-flex">
                                                    <div className="card instructor-card w-100">
                                                        <div>
                                                            <div className="instructor-inner">
                                                                <h6 style={{ color: "black" }}>Inprogress</h6>
                                                                <h4 className="instructor-text-success">{Dashboarddata && Dashboarddata?.data && Dashboarddata?.data[0]?.inProgress}</h4>
                                                                {/* <p style={{ color: "black" }}> Inprogress in this month</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 d-flex">
                                                    <div className="card instructor-card w-100">
                                                        <div>
                                                            <div className="instructor-inner">
                                                                <h6 style={{ color: "black" }}>Pending</h6>
                                                                <h4 className="instructor-text-success">{Dashboarddata && Dashboarddata?.data && Dashboarddata?.data[0]?.pending}</h4>
                                                                {/* <p style={{ color: "black" }}>Pending Order of this month</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 d-flex">
                                                    <div className="card instructor-card w-100">
                                                        <div>
                                                            <div className="instructor-inner">
                                                                <h6 style={{ color: "black" }}>Cancelled</h6>
                                                                <h4 className="instructor-text-success">{Dashboarddata && Dashboarddata?.data && Dashboarddata?.data[0]?.cancelled}</h4>
                                                                {/* <p style={{ color: "black" }}> Inprogress in this month</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Instructor Dashboard */}
                    </div>
                </div>
            </div>
            {/* Page Wrapper */}
            <Footer />
        </div>
    );
}