import React from "react";

const Invoice = () => {
    const handlePrint = () => {
        window.print();
    };

    return (
        <div style={{ background: "#b79205" }}>
            <div className="container bootstrap snippets bootdey" style={{ width: "90%", margin: "0 auto" }}>
                <div className="row">
                    <div className="col-md-12">
                        <div style={{ background: "#FFF", border: "1px solid #CDD3E2", boxShadow: "0px 0px 1px #CCC", padding: "40px 40px 60px", marginTop: "40px", borderRadius: "4px" }}>
                            <div className="payment-info">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <h3>Logo</h3>
                                    </div>
                                    <div className="col-sm-4">
                                        <div style={{ color: "#000", fontSize: "30px", fontWeight: "600" }}>Cleaning Service</div>
                                        <div style={{ color: "gray" }}>www.website.com</div>
                                        <div style={{ color: "gray" }}>website@gmail.com</div>
                                        <div style={{ color: "gray" }}>+91 0000 000000</div>
                                    </div>
                                    <div className="col-sm-6 text-right" style={{ paddingTop: "40px" ,textAlign:"end"}}>
                                        <div style={{ color: "gray" }}>Business Address</div>
                                        <div style={{ color: "gray" }}>City, state, IN 0000,000</div>
                                        <div style={{ color: "gray" }}>TAX ID 00XXXXDOXXXX98</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderTop: "2px solid #EBECEE", marginTop: "30px", paddingTop: "20px", lineHeight: "22px" }}>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div>Billed</div>
                                        <strong>Client Name</strong>
                                        <div>
                                            work address <br />
                                            City ,country , 000000 <br />
                                            +(0)0000,0000 <br />
                                        </div>
                                    </div>
                                    <div className="col-sm-4" style={{ textAlign: "center" }}>
                                        <div>Invoice Number</div>
                                        <div>#AI99865</div>
                                        <div style={{ paddingTop: "30px" }}>
                                            <div>Reference</div>
                                            <div>INV_876</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 text-right" style={{textAlign:"end"}}>
                                        <div>Invoice of (USD)</div>
                                        <strong>$152.00</strong>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-sm-4">
                                        <div>Subject</div>
                                        <strong>Cleaning Service</strong>
                                    </div>
                                    <div className="col-sm-4" style={{ textAlign: "center" }}>
                                        <div>Invoice Date</div>
                                        <strong>26 Nov 2023</strong>
                                    </div>
                                    <div className="col-sm-4 text-right"  style={{textAlign:"end"}}>
                                        <div>Due Date</div>
                                        <strong>30 Nov 2023</strong>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: "40px" }}>
                                <div style={{ color: "#A9B0BB", fontSize: "13px", letterSpacing: ".3px", borderBottom: "2px solid #EBECEE", paddingBottom: "4px" }}>
                                    <div className="row">
                                        <div className="col-6">ITEMS DETAIL</div>
                                        <div className="col-2 text-right">HRS</div>
                                        <div className="col-2 text-right">RATE</div>
                                        <div className="col-2 text-right"  style={{textAlign:"end"}}>AMOUNT</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "8px", borderBottom: "2px solid #EBECEE", paddingBottom: "8px" }}>
                                    <div className="row item">
                                        <div className="col-6 desc">
                                            <strong>Service 1</strong> <br />
                                            <div>Service 1 description</div>
                                        </div>
                                        <div className="col-2 qty text-right">4</div>
                                        <div className="col-2 amount text-right">$30.00</div>
                                        <div className="col-2 amount text-right"  style={{textAlign:"end"}}>$120.00</div>
                                    </div>
                                    <div className="row item">
                                        <div className="col-6 desc">
                                            <strong>Service 2</strong> <br />
                                            <div>Service 2 description</div>
                                        </div>
                                        <div className="col-2 qty text-right">2</div>
                                        <div className="col-2 amount text-right">$24.00</div>
                                        <div className="col-2 amount text-right"  style={{textAlign:"end"}}>$48.00</div>
                                    </div>
                                </div>
                                <div className="total text-right" style={{ marginTop: "15px" }}>
                                    <div className="row item">
                                        <div className="col-6 desc">&nbsp;</div>
                                        <div className="col-3 qty text-right">Subtotal</div>
                                        <div className="col-3 amount text-right"  style={{textAlign:"end"}}>$160.00</div>
                                    </div>
                                    <div className="row item1" style={{ marginTop: "8px", borderBottom: "2px solid #EBECEE", paddingBottom: "8px" }}>
                                        <div className="col-6 desc">&nbsp;</div>
                                        <div className="col-3 qty text-right">Tax (10%)</div>
                                        <div className="col-3 amount text-right"  style={{textAlign:"end"}}>$16.00</div>
                                    </div>
                                    <div className="row item">
                                        <div className="col-6 desc">&nbsp;</div>
                                        <strong className="col-3 qty text-right">Total</strong>
                                        <strong className="col-3 amount text-right"  style={{textAlign:"end"}}>$152.00</strong>
                                    </div>
                                </div>
                                <div className="print" style={{ marginTop: "50px", textAlign: "center" }}>
                                    <a href="#" onClick={handlePrint} style={{ display: "inline-block", border: "1px solid #9CB5D6", padding: "13px 13px", borderRadius: "5px", color: "#708DC0", fontSize: "13px", transition: "all 0.2s linear" }}>
                                        <i className="fa fa-print"></i>
                                        Print this receipt
                                    </a>
                                </div>
                                <div className="thanks" style={{ bottom: "0px", textAlign: "start", paddingTop: "10%" }}>
                                    <div>Thanks for the business</div>
                                </div>
                            </div>
                        </div>
                        <div className="footer" style={{ marginTop: "40px", marginBottom: "110px", textAlign: "center", fontSize: "15px", color: "#969CAD",padding:"10px 0px" }}>
                            Copyright © 2024. company name
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Invoice;
