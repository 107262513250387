import React, { useEffect, useState } from "react";
import InstructorSidebar from "../sidebar";
import Header from "../../header/index-two";
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import { Footer } from "../../footer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Swal from 'sweetalert2';

export default function Units() {
  const userData = JSON.parse(localStorage.getItem('userData'));

  const [PropertyDetails, setPropertyDetails] = useState('');
  const [areaDetails, setAreaDetails] = useState('');
  const [propertyUnits, setPropertyUnits] = useState([{ propertyType: '', unitNumber: '' }]);

  const navigate = useNavigate();

  const [propertyname, setPropertyName] = useState('');
  const [address, setAddress] = useState('');
  const [areavalue, setAreaValue] = useState('');
  const [Googleaddress, setGoogleAddress] = useState('');
  const [wifi, setWifi] = useState('');
  const [notes, setNotes] = useState('');



  // Validation states and error messages
  const [nameError, setNameError] = useState('');
  const [areaError, setAreaError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [unitErrors, setUnitErrors] = useState([]);

  useEffect(() => {
    AreaDropdownFun();
    PropertyDropdownFun();
  }, []);

  const AreaDropdownFun = () => {
    axios.get(`${process.env.React_App_Url}/api/v1/area/dropdown/list`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    })
      .then((response) => {
        setAreaDetails(response?.data);
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  };

  const PropertyDropdownFun = () => {
    axios.get(`${process.env.React_App_Url}/api/v1/frontend/consumer/cleaning/property/type`, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    })
      .then((response) => {
        setPropertyDetails(response?.data);
        toast.success(response.data, { position: 'top-right' });
      })
      .catch((error) => {
        toast.error(error.response.data);
      });
  };

  const AreaOptions = areaDetails?.data?.map((item) => ({
    label: item?.name,
    value: item?.uuid,
  })) || [];

  const PropertyDropdownOption = [{ label: "Select Property Type", value: "" }];
  if (PropertyDetails?.data) {
    Object.entries(PropertyDetails?.data).forEach(([key, value]) => {
      PropertyDropdownOption.push({ value: key, label: value });
    });
  }

  const handleAddPropertyUnit = () => {
    setPropertyUnits([...propertyUnits, { propertyType: '', unitNumber: '' }]);
  };

  const handleRemovePropertyUnit = (index) => {
    setPropertyUnits(propertyUnits.filter((_, i) => i !== index));
  };

  const handlePropertyTypeChange = (index, event) => {
    const updatedPropertyUnits = propertyUnits.map((item, i) =>
      i === index ? { ...item, propertyType: event } : item
    );
    setPropertyUnits(updatedPropertyUnits);
  };

  const handleUnitNumberChange = (index, event) => {
    const updatedPropertyUnits = propertyUnits.map((item, i) =>
      i === index ? { ...item, unitNumber: event.target.value } : item
    );
    setPropertyUnits(updatedPropertyUnits);
  };

  const validateForm = () => {
    let isValid = true;

    if (!propertyname) {
      setNameError('Property Name is required');
      isValid = false;
    } else {
      setNameError('');
    }

    if (!areavalue) {
      setAreaError('Area is required');
      isValid = false;
    } else {
      setAreaError('');
    }

    if (!address) {
      setAddressError('Address is required');
      isValid = false;
    } else {
      setAddressError('');
    }

    const unitErrors = [];
    propertyUnits.forEach((unit, index) => {
      if (!unit.propertyType || !unit.propertyType.value) {
        unitErrors[index] = { propertyType: 'Property Type is required' };
        isValid = false;
      } else {
        unitErrors[index] = { propertyType: '' };
      }

      if (!unit.unitNumber) {
        unitErrors[index] = { ...unitErrors[index], unitNumber: 'Unit Number is required' };
        isValid = false;
      } else {
        unitErrors[index] = { ...unitErrors[index], unitNumber: '' };
      }
    });

    setUnitErrors(unitErrors);

    return isValid;
  };

  const propertyDetailesFilter = ((datapropertyUnits) => {
    const emptyArray = []
    datapropertyUnits?.map((item) => {
      emptyArray?.push({ property_type: item?.propertyType?.value, unit_number: item?.unitNumber })
    })
    return emptyArray;
  })

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const payload = {
      property_building_name: propertyname,
      area_uuid: areavalue?.value,
      address: address,
      unit_details: propertyDetailesFilter(propertyUnits),
      building_google_address: Googleaddress,
      wifi_details : wifi,
      notes : notes
    };

    axios.post(`${process.env.React_App_Url}/api/v1/frontend/addNewUnit`, payload, {
      headers: {
        authorization: `Bearer ${userData?.accessToken}`,
      },
    })
      .then((response) => {
        toast.success(response.data, { position: 'top-right' });
        Swal.fire({
          icon: 'success',
          title: response.data?.message,
          text: response.data?.message,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/Units');
          }
        });
      })
      .catch((error) => {
        toast.error(error.response.data);
        Swal.fire({
          icon: 'error',
          title: error.response.data?.message,
          text: error.response.data?.message,
        });
      });
  };

  return (
    <div className="main-wrapper">
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <InstructorSidebar activeMenu="AddUnit" />
            <div className="col-xl-9 col-lg-8 col-md-12">
              <div className="settings-widget">
                <div className="settings-inner-blk p-0">
                  <div className="sell-course-head comman-space">
                    <h3>Add Units</h3>
                  </div>
                  <div className="row mx-3 mt-4">
                    <div className="col-md-12">
                      <form onSubmit={handleSubmit}>
                        <div className="row mx-5">
                          <div className="col-md-6 col-item">
                            <div className="form-group">
                              <label htmlFor="address">Property Name</label>
                              <div className="search-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  placeholder="Enter Property Name"
                                  value={propertyname}
                                  onChange={(e) => setPropertyName(e.target.value)}
                                />
                                {nameError && <span className="text-danger">{nameError}</span>}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-item">
                            <div className="form-group">
                              <label htmlFor="area">Area</label>
                              <div className="search-group">
                                <Select
                                  className="select"
                                  name="area"
                                  options={AreaOptions}
                                  placeholder="Choose Area"
                                  onChange={(e) => setAreaValue(e)}
                                  value={areavalue}
                                />
                                {areaError && <span className="text-danger">{areaError}</span>}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-item">
                            <div className="form-group">
                              <label htmlFor="address">Address</label>
                              <input
                                type="text"
                                className="form-control"
                                id="address"
                                placeholder="Enter Address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                              {addressError && <span className="text-danger">{addressError}</span>}
                            </div>
                          </div>
                          <div className="col-md-6 col-item">
                            <div className="form-group">
                              <label htmlFor="googleAddress">Building Google Address</label>
                              <input
                                type="text"
                                className="form-control"
                                id="googleAddress"
                                placeholder="Enter Google Address"
                                value={Googleaddress}
                                onChange={(e) => setGoogleAddress(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-item">
                            <div className="form-group">
                              <label htmlFor="wifi_details">Wifi Details</label>
                              <input
                                type="text"
                                className="form-control"
                                id="wifi_details"
                                placeholder="Enter Wifi Details"
                                value={wifi}
                                onChange={(e) => setWifi(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-item">
                            <div className="form-group">
                              <label htmlFor="notes">Notes</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="notes"
                                placeholder="Enter Notes"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-12 d-flex justify-content-end">
                            <button type="button" className="btn btn-sm btn-primary" onClick={handleAddPropertyUnit}>+</button>
                          </div>
                          <div className="col-md-12 col-item">
                            {propertyUnits.map((item, index) => (
                              <div key={index} className="row">
                                <div className="col-md-5">
                                  <div className="form-group">
                                    <label htmlFor={`propertyType-${index}`}>Property Type</label>
                                    <Select
                                      className="select"
                                      name={`propertyType-${index}`}
                                      options={PropertyDropdownOption}
                                      placeholder="Select Property Type"
                                      onChange={(e) => handlePropertyTypeChange(index, e)}
                                      value={item.propertyType}
                                    />
                                    {unitErrors[index]?.propertyType && <span className="text-danger">{unitErrors[index].propertyType}</span>}
                                  </div>
                                </div>
                                <div className="col-md-5">
                                  <div className="form-group">
                                    <label htmlFor={`unitNumber-${index}`}>Unit Number</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id={`unitNumber-${index}`}
                                      placeholder="Enter Unit Number"
                                      value={item.unitNumber}
                                      onChange={(e) => handleUnitNumberChange(index, e)}
                                    />
                                    {unitErrors[index]?.unitNumber && <span className="text-danger">{unitErrors[index].unitNumber}</span>}
                                  </div>
                                </div>
                                <div className="col-md-2 d-flex align-items-center">
                                  <button type="button" className="btn btn-danger mt-4" onClick={() => handleRemovePropertyUnit(index)}>Remove</button>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="col-md-12 col-item mt-3 mb-4">
                            <button type="submit" className="btn btn-primary">Add Unit</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
